import { action, makeObservable, observable } from 'mobx';
import { GroupInterface } from 'types/GroupInterface';
import { BaseStore } from './BaseStore';

class GroupStore extends BaseStore {

    groups: Array<GroupInterface> = [];

    constructor() {
        super();

        makeObservable(this, {
            groups: observable,

            resetStore: action,
            getGroups: action
        })
    }

    resetStore = () => {
        this.groups = [];
    }

    getGroups = () => {
        return this.get(`groups`, 'groups', false);
    }

}

export const groupStore = new GroupStore();