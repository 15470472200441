import LoggedInContainer from "components/LoggedInContainer"
import PageFront from "components/Pages/User/PageFront"
import PageLogin from "components/Pages/PageLogin"
import PagePlaceOrder from "components/Pages/PagePlaceOrder"
import { unstable_HistoryRouter as HistoryRouter, Route, Routes } from "react-router-dom"
import ToastContainer from 'components/ToastContainer';
import { createBrowserHistory } from "history"
import { observer } from 'mobx-react-lite'
import { authStore } from 'stores/AuthStore'
import { UrlPathEnum } from 'types/UrlPathEnum'
import AdminRoutes from './AdminRoutes'
import PageForgotPassword from 'components/Pages/PageForgotPassword'
import PageResetPassword from 'components/Pages/PageResetPassword'
import PageUserOrders from 'components/Pages/User/PageUserOrders'
import PageUserProfile from 'components/Pages/PageUserProfile'
import PageUserBackOrders from 'components/Pages/User/PageUserBackOrders'

export const history = createBrowserHistory();

export const routes = {
    pageFront: '/',
    pageLogin: '/:urlpath',
    pageForgotPassword: '/forgot-password',
    pageResetPassword: '/reset-password/:token',
    pageHistory: '/order-history',
    pageHistoryBackOrders: '/order-history/backorders',
    pagePlaceOrder: '/place-order',
    pageUserProfile: '/user-profile'
}

const MyRoutes = observer(() => {

    return (
        <div>
            <ToastContainer position='top-center' autoClose={2000} />
            <HistoryRouter history={history}>
                {
                    authStore.urlpath !== UrlPathEnum.ADMIN &&
                    <Routes>
                        <Route path={routes.pageLogin} element={<PageLogin />} />
                        <Route path={routes.pageForgotPassword} element={<PageForgotPassword />} />
                        <Route path={routes.pageResetPassword} element={<PageResetPassword />} />
                        <Route element={<LoggedInContainer />}>
                            <Route path={routes.pageFront} element={<PageFront />} />
                            <Route path={routes.pagePlaceOrder} element={<PagePlaceOrder />} />
                            <Route path={routes.pageHistory} element={<PageUserOrders />} />
                            <Route path={routes.pageHistoryBackOrders} element={<PageUserBackOrders />} />
                            <Route path={routes.pageUserProfile} element={<PageUserProfile />} />
                        </Route>
                    </Routes>
                }

                {
                    authStore.urlpath === UrlPathEnum.ADMIN &&
                    <AdminRoutes />
                }


            </HistoryRouter>
        </div>

    )
})

export default MyRoutes;