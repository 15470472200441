import { routes } from 'components/Routes';
import { adminRoutes } from 'components/Routes/AdminRoutes';
import SidebarMenuContainer from "components/SidebarMenuContainer";
import SidebarMenuItem from "components/SidebarMenuContainer/SidebarMenuItem";
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { authStore } from 'stores/AuthStore';
import { groupStore } from 'stores/GroupStore';
import { GroupInterface } from 'types/GroupInterface';
import { UrlPathEnum } from 'types/UrlPathEnum';

export enum ActiveMenuEnum {
    USERS,
    ORDERS,
    BACK_ORDERS,
    PRODUCTS,
    GROUPS,
    ADDRESSES,
    NEWS
}

interface Props {
    children: React.ReactNode,
    activeMenuItem: ActiveMenuEnum
}

const SidebarMenuContainerAdmin = observer((props: Props) => {

    const navigate = useNavigate();

    useEffect(() => {
        groupStore.getGroups();
    }, [])

    const exchangeToken = async (group: GroupInterface) => {
        const urlPath = group.urlPath.replace('/', '') as UrlPathEnum;

        await authStore.exchangeToken(group.id, urlPath);
        navigate(routes.pageFront);
        window.location.reload();
    }

    const groups = _.orderBy(groupStore.groups, g => g.name);

    return (
        <SidebarMenuContainer
            sidebarMenuContent={
                <>
                    <SidebarMenuItem
                        onClick={() => navigate(adminRoutes.pageNews)}
                        label='Nyheder'
                        isActive={props.activeMenuItem === ActiveMenuEnum.NEWS}
                    />
                    <SidebarMenuItem
                        onClick={() => navigate(adminRoutes.pageUsers)}
                        label='Brugere'
                        isActive={props.activeMenuItem === ActiveMenuEnum.USERS}
                    />
                    <SidebarMenuItem
                        onClick={() => navigate(adminRoutes.pageOrders)}
                        label='Ordrehistorik'
                        isActive={props.activeMenuItem === ActiveMenuEnum.ORDERS}
                    />
                    <SidebarMenuItem
                        onClick={() => navigate(adminRoutes.pageBackOrders)}
                        label='Restordrer'
                        isActive={props.activeMenuItem === ActiveMenuEnum.BACK_ORDERS}
                    />
                    <SidebarMenuItem
                        onClick={() => navigate(adminRoutes.pageProducts)}
                        label='Produkter'
                        isActive={props.activeMenuItem === ActiveMenuEnum.PRODUCTS}
                    />
                    <SidebarMenuItem
                        onClick={() => navigate(adminRoutes.pageAddresses)}
                        label='Adresser'
                        isActive={props.activeMenuItem === ActiveMenuEnum.ADDRESSES}
                    />

                    <br /><br />
                    <p className='font-semibold mb-2'>Login som anden profil</p>
                    {
                        groups.map((group, index) => {
                            return (
                                <SidebarMenuItem
                                    key={index}
                                    onClick={() => exchangeToken(group)}
                                    label={group.name}
                                />
                            )
                        })
                    }
                </>
            }>
            {props.children}
        </SidebarMenuContainer>

    )
})

export default SidebarMenuContainerAdmin;