import SelectInput from "components/Forms/SelectInput";
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { addressStore } from 'stores/AddressStore';

interface Props {
    name: string
}

const SelectAddress = observer((props: Props) => {

    useEffect(() => {
        addressStore.getAddresses();
    }, [])

    const addresses = _.orderBy(addressStore.addresses, a => a.name?.toLowerCase() || '');
    if (addresses.length === 0) return null;

    return (
        <SelectInput
            label='Leveringssted'
            placeholder='Vælg adresse'
            name={props.name}
            options={addresses.map((address) => ({
                id: address.id,
                value: address.name || `${address.street}, ${address.zip} ${address.city}`
            }))}
            isClearable={true}
        />
    )
})

export default SelectAddress;