import Button from 'components/Forms/Button';
import Form from 'components/Forms/Form';
import Input from 'components/Forms/Input';
import Textarea from 'components/Forms/Textarea';
import Header from 'components/Header';
import { adminRoutes } from 'components/Routes/AdminRoutes';
import SelectCategory from 'components/SelectCategory';
import SelectSex from 'components/SelectSex';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { productDetailsStore } from 'stores/ProductDetailsStore';
import ExpectedInStock from './ExpectedInStock';

const NAME_NAME = 'name';
const CATEGORY_NAME = 'category';
const SEX_NAME = 'sex';
const COLOR_NAME = 'color';
const DESCRIPTION_NAME = 'description';


const PageProductDetails = observer(() => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    useEffect(() => {
        productDetailsStore.getProduct(id!);

        return () => {
            productDetailsStore.resetStore();
        }
    }, [id])

    const onSubmit = async (data: any) => {
        const product = productDetailsStore.product!;

        product.name = data[NAME_NAME];
        product.category = { id: data[CATEGORY_NAME] } as any;
        product.sex = data[SEX_NAME];
        product.color = data[COLOR_NAME];
        product.description = data[DESCRIPTION_NAME];

        await productDetailsStore.updateProduct(product);
        toast('Ændringer gemt', { type: 'success' })
    }

    const product = productDetailsStore.product;
    if (!product) return null;

    return (
        <div>
            <Header />
            <div className='flex'>
                <div className='flex-1'>
                    <div className='my-16 w-72 mx-auto'>
                        <h1 className='text-2xl mb-12'>Ret produkt</h1>
                        <Form
                            onSubmit={onSubmit}
                            formOptions={{
                                defaultValues: {
                                    [NAME_NAME]: product.name,
                                    [CATEGORY_NAME]: product.category.id,
                                    [SEX_NAME]: product.sex,
                                    [COLOR_NAME]: product.color,
                                    [DESCRIPTION_NAME]: product.description,
                                }
                            }}
                            validation={(yup) => ({
                                [NAME_NAME]: yup.string().required('Påkrævet'),
                                [CATEGORY_NAME]: yup.string().required('Påkrævet'),
                                [SEX_NAME]: yup.string().required('Påkrævet'),
                                [COLOR_NAME]: yup.string().nullable(),
                                [DESCRIPTION_NAME]: yup.string().nullable()
                            })}>

                            <Input
                                label='Produktets navn'
                                name={NAME_NAME}
                            />

                            <SelectCategory
                                label='Kategori'
                                name={CATEGORY_NAME}
                                className='mt-6'
                            />

                            <SelectSex
                                label='Køn'
                                name={SEX_NAME}
                                className='mt-6'
                            />

                            <Input
                                label='Farve'
                                name={COLOR_NAME}
                                className='mt-6'
                            />

                            <Textarea
                                label='Beskrivelse'
                                name={DESCRIPTION_NAME}
                                className='mt-6'
                            />

                            <div className='flex justify-around mt-12'>
                                <Button
                                    type='submit'
                                    appearance='primary'
                                    label='Gem ændringer'
                                />
                            </div>
                        </Form>
                    </div >
                </div>

                <ExpectedInStock
                    productId={id!}
                />
            </div>
            <div className='py-8 text-center border-t'>
                <Button
                    type='button'
                    appearance='secondary'
                    label='Tilbage til produktoversigt'
                    onClick={() => navigate(adminRoutes.pageProducts)}
                />
            </div>
        </div >
    )

})

export default PageProductDetails;