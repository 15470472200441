import { action, makeObservable, observable } from 'mobx';
import { UserInterface } from 'types/UserInterface';
import { BaseStore } from './BaseStore';

class UserDetailsStore extends BaseStore {

    user: UserInterface | null = null;

    constructor() {
        super();

        makeObservable(this, {
            user: observable,

            resetStore: action,
            getUser: action,
            updateUser: action,
            deleteUser: action,
            addUserToGroup: action,
            removeUserFromGroup: action
        })
    }

    resetStore = () => {
        this.user = null;
    }

    getUser = (id: string) => {
        return this.get(`users/${id}`, 'user');
    }

    updateUser = (user: UserInterface) => {
        return this.put(`users/${user.id}`, user, 'user');
    }

    deleteUser = (userId: string) => {
        return this.delete(`users/${userId}`, userId, this.user);
    }

    addUserToGroup = (userId: string, groupId: string) => {
        return this.post(`users/${userId}/groups`, {
            id: groupId
        }, this.user, true);
    }

    removeUserFromGroup = (userId: string, groupId: string) => {
        return this.delete(`users/${userId}/groups/${groupId}`, groupId, this.user?.groups);
    }

}

export const userDetailsStore = new UserDetailsStore();