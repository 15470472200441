import _ from 'lodash';
import React, { KeyboardEvent, useMemo } from 'react';

interface Props {
    placeholder?: string,
    onChange: (value: string) => void
}

const InputFilter = (props: Props) => {
    
    const { onChange } = props;

    const onKeyDown = (e?: KeyboardEvent<HTMLInputElement>) => {
        if (e?.key === 'Enter') {
            onChange(e.currentTarget.value);
        }
    }

    const debounceOnChange = useMemo(() => _.debounce(onChange, 1000), [onChange])

    return (
        <input
            type="text"
            className='border w-full px-2 rounded'
            placeholder={props.placeholder}
            onKeyDown={onKeyDown}
            // onChange={(e) => debounceOnChange(e.currentTarget.value)}
        />
    )
}

export default InputFilter;