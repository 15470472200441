import { action, makeObservable, observable } from 'mobx';
import { UserInterface } from 'types/UserInterface';
import { UserRoleEnum } from 'types/UserRoleEnum';
import { BaseStore } from './BaseStore';

class UserStore extends BaseStore {

    users: Array<UserInterface> = [];

    constructor() {
        super();

        makeObservable(this, {
            users: observable,

            resetStore: action,
            getUsers: action,
            createUser: action
        })
    }

    resetStore = () => {
        this.users = [];
    }

    getUsers = () => {
        return this.get(`users`, 'users');
    }

    createUser = (name: string, email: string, role: UserRoleEnum) => {
        return this.post(`users`, {
            name: name,
            email: email,
            role: role
        }, this.users)
    }

}

export const userStore = new UserStore();