import Header from 'components/Header';
import { adminRoutes } from 'components/Routes/AdminRoutes';
import SidebarMenuContainerAdmin, { ActiveMenuEnum } from 'components/SidebarMenuContainerAdmin';
import Table from 'components/Table';
import { productSexToString } from 'helpers/ProductHelper';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { productStore } from 'stores/ProductStore';
import { ProductInterface } from 'types/ProductInterface';

const pageProducts = observer(() => {
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        productStore.getProducts();

        return () => {
            productStore.resetStore();
        }
    }, [])

    const products = _.filter(productStore.products, (p) => {
        if (!searchQuery) return true;

        const variantSkuIdentifier = p.variants[0]?.sku?.split('-')[0] || null;

        return (
            (p.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1) ||
            (searchQuery.length === 4 && variantSkuIdentifier !== null && variantSkuIdentifier === searchQuery)
        )
    });

    return (
        <div>
            <Header />
            <SidebarMenuContainerAdmin
                activeMenuItem={ActiveMenuEnum.PRODUCTS}>
                <div className='flex justify-between items-center mb-6'>
                    <input
                        onChange={(e) => setSearchQuery(e.currentTarget.value)}
                        type="text"
                        className='border mb-2 py-2 px-4 rounded w-64'
                        placeholder='Søg på produktets navn...'
                    />
                </div>

                <Table
                    data={products}
                    render={[
                        {
                            label: 'Navn',
                            sortProperty: (item) => item.name,
                            cell: (item) => item.name
                        },
                        {
                            label: 'Kategori',
                            sortProperty: (item) => item.category?.name,
                            cell: (item) => item.category?.name
                        },
                        {
                            label: 'Køn',
                            sortProperty: (item) => productSexToString(item.sex),
                            cell: (item) => productSexToString(item.sex)
                        },
                        {
                            label: 'Farve',
                            sortProperty: (item) => item.color,
                            cell: (item) => item.color
                        }
                    ]}
                    onRowClicked={(item: ProductInterface) => navigate(generatePath(adminRoutes.pageProductDetails, { id: item.id }))}
                />

            </SidebarMenuContainerAdmin>
        </div>
    )
})

export default pageProducts;