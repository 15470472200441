import { routes } from "components/Routes";
import SidebarMenuContainer from "components/SidebarMenuContainer";
import SidebarMenuItem from "components/SidebarMenuContainer/SidebarMenuItem";
import { useNavigate } from "react-router-dom";

export enum ActiveMenuEnum {
    ORDER_PRODUCTS,
    ORDER_HISTORY,
    BACK_ORDERS
}

interface Props {
    children: React.ReactNode,
    activeMenuItem: ActiveMenuEnum
}

const SidebarMenuContainerFrontpage = (props: Props) => {

    const navigate = useNavigate();

    return (
        <SidebarMenuContainer
            sidebarMenuContent={
                <>
                    <SidebarMenuItem
                        onClick={() => navigate(routes.pageFront)}
                        label='Bestil produkter'
                        isActive={props.activeMenuItem === ActiveMenuEnum.ORDER_PRODUCTS}
                    />
                    <SidebarMenuItem
                        onClick={() => navigate(routes.pageHistory)}
                        label='Ordrehistorik'
                        isActive={props.activeMenuItem === ActiveMenuEnum.ORDER_HISTORY}
                    />
                    <SidebarMenuItem
                        onClick={() => navigate(routes.pageHistoryBackOrders)}
                        label='Restordrer'
                        isActive={props.activeMenuItem === ActiveMenuEnum.BACK_ORDERS}
                    />
                </>
            }>
            {props.children}
        </SidebarMenuContainer>

    )
}

export default SidebarMenuContainerFrontpage;