import { action, makeObservable, observable } from 'mobx';
import { AddressInterface } from 'types/AddressInterface';
import { BaseStore } from './BaseStore';

class AddressStore extends BaseStore {

    addresses: Array<AddressInterface> = [];

    constructor() {
        super();

        makeObservable(this, {
            addresses: observable,

            resetStore: action,
            getAddresses: action,
            createAddress: action
        })
    }

    resetStore = () => {
        this.addresses = [];
    }

    getAddresses = () => {
        return this.get(`addresses`, 'addresses');
    }

    createAddress = (name: string, street: string, zip: string, city: string) => {
        return this.post(`addresses`, {
            name: name,
            street: street,
            zip: zip,
            city: city
        }, this.addresses);
    }

}

export const addressStore = new AddressStore();