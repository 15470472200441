import BackToFrontpageButton from "components/BackToFrontpageButton";
import Cart from "components/Cart";
import Header from "components/Header";
import ProductItem from "components/ProductItem";
import { routes } from "components/Routes";
import SidebarMenuContainer from "components/SidebarMenuContainer";
import SidebarMenuItem from "components/SidebarMenuContainer/SidebarMenuItem";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { placeOrderStore } from "stores/PlaceOrderStore";
import { productStore } from "stores/ProductStore";
import _ from 'lodash';
import { categoryStore } from 'stores/CategoryStore';
import { authStore } from 'stores/AuthStore';
import ProductFilter from './ProductFilter';

const PagePlaceOrder = observer(() => {
    const [categoryId, setCategoryId] = useState<string | null>(null);

    const navigate = useNavigate();

    useEffect(() => {
        categoryStore.getCategories();
        productStore.getProducts();

        if (!placeOrderStore.canOrder) {
            navigate(routes.pageFront);
        }
    }, [])

    let products = productStore.filteredProducts;

    if (categoryId !== null) {
        products = _.filter(products, (p) => p.category?.id === categoryId);
    }

    return (
        <div>
            <Header />
            <SidebarMenuContainer
                rightColumn={<Cart />}
                sidebarMenuContent={
                    <>
                        <BackToFrontpageButton />

                        <div className='mb-4'>
                            <SidebarMenuItem
                                onClick={() => setCategoryId(null)}
                                label='Alle produkter'
                                isActive={categoryId === null}
                            />
                        </div>

                        {
                            _.orderBy(categoryStore.categories, c => c.name).map((category, index) => {
                                return (
                                    <SidebarMenuItem
                                        key={index}
                                        onClick={() => setCategoryId(category.id)}
                                        label={category.name}
                                        isActive={categoryId === category.id}
                                    />
                                )
                            })
                        }
                    </>
                }>
                <p className='font-semibold text-primary-green'>
                    Bestil produkter {placeOrderStore.employeeNo ? `til medarbejder ${placeOrderStore.employeeNo}` : `til ${authStore.user?.name}`}
                </p>

                <ProductFilter />

                <div className='mt-8'>
                    {
                        products.map((product) => {
                            return (
                                <ProductItem key={product.id} product={product} />
                            )
                        })
                    }
                </div>
            </SidebarMenuContainer>

        </div>
    )
})

export default PagePlaceOrder;