import Select from 'components/Forms/Select'
import { productSexToString } from 'helpers/ProductHelper'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { categoryStore } from 'stores/CategoryStore'

interface Props {
    label: string,
    name: string,
    className?: string
}

const SelectCategory = observer((props: Props) => {

    useEffect(() => {
        categoryStore.getCategories();

        return () => {
            categoryStore.resetStore();
        }
    }, [])

    const categories = _.orderBy(categoryStore.categories, c => c.name);
    if (categories.length === 0) return null;

    return (
        <Select
            className={props.className}
            name={props.name}
            label={props.label}
            placeholder='Vælg kategori'
            options={categories.map((category) => ({
                id: category.id,
                value: category.name
            }))}
        />
    )

})

export default SelectCategory;