import { useFormContext } from "react-hook-form";
import FormElementInterface from "types/FormElementInterface";
import Error from "../Error";
import Label from "../Label";

interface Props extends FormElementInterface {
    className?: string
}

const Textarea = (props: Props) => {
    const { register } = useFormContext();

    return (
        <div className={props.className}>
            <Label name={props.name} label={props.label} />
            <textarea
                className='border p-2 h-32 w-full'
                {...register(props.name)}
                defaultValue={props.defaultValue}
                placeholder={props.placeholder}
            />
            <Error name={props.name} />
        </div>
    )
}

export default Textarea;