import Button from "components/Forms/Button";
import Form from "components/Forms/Form";
import Input from "components/Forms/Input";
import Header from "components/Header";
import SelectReasonForOrdering from "components/SelectReasonForOrdering";
import SidebarMenuContainerFrontpage, { ActiveMenuEnum } from "components/SidebarMenuContainerFrontPage";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { YupInterface } from "types/YupInterface";
import { observer } from 'mobx-react-lite';
import { placeOrderStore } from "stores/PlaceOrderStore";
import { routes } from "components/Routes";
import SelectEmployee from "components/SelectEmployee";
import { authStore } from "stores/AuthStore";
import { ReasonForOrderEnum } from 'types/ReasonForOrderEnum';
import { EmployeeNumberRuleEnum } from 'types/EmployeeNumberRuleEnum';
import NewsBox from 'components/NewsBox';
import { UserRoleEnum } from 'types/UserRoleEnum';
import { selectEmployeeStore } from 'stores/SelectEmployeeStore';
import _ from 'lodash';
import { productStore } from 'stores/ProductStore';
import { categoryStore } from 'stores/CategoryStore';

const EMPLOYEE_NUMBER_NAME = 'employeeNo';
const REASON_FOR_ORDER_NAME = 'reason';

const PageFront = observer(() => {

    const navigate = useNavigate();

    useEffect(() => {
        if (placeOrderStore.employeeNo && placeOrderStore.reasonForOrder) {
            navigate(routes.pagePlaceOrder);
        }
        else {
            // Used when placing order. Get products and categories ahead of time to speed up
            // impression of performance.
            productStore.getProducts();
            categoryStore.getCategories();
        }
    }, [])

    const onSubmit = async (data: {
        [REASON_FOR_ORDER_NAME]: ReasonForOrderEnum,
        [EMPLOYEE_NUMBER_NAME]: { id: string, name: string } | string
    }) => {

        // Determine whether employeeNo was entered "freely" in an input field
        // or selected from a dropdown. We only care about the id/employeeNo, 
        // so that is what we're looking for.
        if (authStore.group?.employeeNumberRule !== EmployeeNumberRuleEnum.NO_RULES) {
            let employeeNo: string;
            if (typeof data[EMPLOYEE_NUMBER_NAME] === 'string') {
                employeeNo = data[EMPLOYEE_NUMBER_NAME] as string;
            }
            else {
                employeeNo = (data[EMPLOYEE_NUMBER_NAME] as any).id;
            }

            if (authStore.group?.employeeNumberRule === EmployeeNumberRuleEnum.REQUIRE_UNKNOWN_EMPLOYEE_NUMBER) {
                const existingEmployees = await selectEmployeeStore.getEmployees(employeeNo);
                if (_.some(existingEmployees, e => e.number.toLowerCase() === employeeNo.toLowerCase())) {
                    alert(`FEJL!\n\nMedarbejder ${employeeNo} findes allerede på den aktuelle månedlige medarbejderliste.\n\nDen profiltype du er logget ind som må udelukkende bestille til nye medarbejdere, der ikke fremgår af denne liste.`);
                    return;
                }
            }

            placeOrderStore.setEmployeeNo(employeeNo);
        }

        placeOrderStore.setReasonForOrder(data[REASON_FOR_ORDER_NAME]);
        navigate(routes.pagePlaceOrder);

    }

    const validation = (yup: YupInterface) => {
        return {
            [EMPLOYEE_NUMBER_NAME]: requireKnownEmployeeNumber()
                ? yup.object().nullable().required('Medarbejdernummer skal udfyldes')
                : requireUnknownEmployeeNumber()
                    ? yup.string().length(8, 'Skal være 8 cifre').required('Medarbejdernummer skal udfyldes')
                    : yup.string(), // No rules - Empployee number not required
            [REASON_FOR_ORDER_NAME]: authStore.group?.requireOrderReason
                ? yup.string().required('Årsag til bestilling skal udfyldes')
                : yup.string()
        }
    }

    const requireKnownEmployeeNumber = () => {
        // return authStore.user?.role !== UserRoleEnum.ADMIN && authStore.group?.employeeNumberRule === EmployeeNumberRuleEnum.REQUIRE_KNOWN_EMPLOYEE_NUMBER;
        return authStore.group?.employeeNumberRule === EmployeeNumberRuleEnum.REQUIRE_KNOWN_EMPLOYEE_NUMBER;
    }

    const requireUnknownEmployeeNumber = () => {
        // if (authStore.user?.role === UserRoleEnum.ADMIN && authStore.group?.employeeNumberRule === EmployeeNumberRuleEnum.REQUIRE_KNOWN_EMPLOYEE_NUMBER) {
        //     return true;
        // }

        return authStore.group?.employeeNumberRule === EmployeeNumberRuleEnum.REQUIRE_UNKNOWN_EMPLOYEE_NUMBER;
    }

    return (
        <div>
            <Header />
            <SidebarMenuContainerFrontpage
                activeMenuItem={ActiveMenuEnum.ORDER_PRODUCTS}>
                <div className='flex'>
                    <div className='w-[300px] mx-auto my-20'>
                        <Form onSubmit={onSubmit} validation={validation}>
                            {
                                // Profile 1 -> Find slutbrugerens medarbejdernummer i en rullemenu eller lign. (1.3.3)
                                requireKnownEmployeeNumber() &&
                                <div className='mb-6'>
                                    <SelectEmployee
                                        name={EMPLOYEE_NUMBER_NAME}
                                    />
                                </div>
                            }

                            {
                                // Profile 2 -> Opret/Tast slutbrugerens medarbejdernummer i en rullemenu eller lign (1.3.4)
                                requireUnknownEmployeeNumber() &&
                                <Input
                                    label='Indtast medarbejdernummer'
                                    name={EMPLOYEE_NUMBER_NAME}
                                    className='mb-6'
                                />
                            }

                            {
                                authStore.group?.requireOrderReason &&
                                <SelectReasonForOrdering
                                    name={REASON_FOR_ORDER_NAME}
                                    className='mb-12'
                                />
                            }

                            <div className='text-center'>
                                <Button
                                    type='submit'
                                    appearance='primary'
                                    label='Start bestilling'
                                />
                            </div>
                        </Form>
                    </div>
                    <NewsBox />
                </div>

            </SidebarMenuContainerFrontpage>
        </div>
    )
})

export default PageFront;