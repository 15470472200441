import Button from 'components/Forms/Button';
import Table from 'components/Table';
import InputFilter from 'components/Table/InputFilter';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { orderStore } from 'stores/OrderStore';
import { OrderInterface } from 'types/OrderInterface';
import 'react-datepicker/dist/react-datepicker.css';
import { authStore } from 'stores/AuthStore';
import { UserRoleEnum } from 'types/UserRoleEnum';
import _ from 'lodash';
import { OrderStatusEnum } from 'types/OrderStatusEnum';
import { orderStatusEnumToString } from 'helpers/ProductHelper';

interface Props {
    backOrders?: boolean
}

const OrdersTable = observer((props: Props) => {
    const [filter, setFilter] = useState<any>({});

    const { backOrders } = props;
    useEffect(() => {
        orderStore.getOrders({}, backOrders ?? false);

        return () => {
            orderStore.resetStore();
        }
    }, [])

    const updateFilter = (key: string, value: string) => {
        const f = filter;
        f[key] = value;
        setFilter(f);

        const backOrders = props.backOrders ?? false;

        orderStore.resetStore();
        orderStore.getOrders(f, backOrders);
    }

    const deleteOrder = async (orderId: string) => {
        if (!window.confirm("Er du sikker på at du vil slette denne ordre?")) return;
        await orderStore.deleteOrder(orderId);
    }

    const orders = orderStore.orders;

    return (
        <div>
            <Table
                data={_.clone(orders?.items) || []}
                render={[
                    {
                        label: 'Ordre #',
                        width: '100',
                        cell: (order) => order.jdOrderId || '-',
                        appendToLabel: <InputFilter onChange={(val) => updateFilter('jdOrderId', val)} />,
                    },
                    {
                        label: 'Dato',
                        width: 150,
                        cell: (item) => {
                            return (
                                <>
                                    <div>
                                        {moment(item.createdOn).format('DD/MM/YYYY HH:mm')}
                                    </div>
                                    <div className='text-xs font-semibold'>
                                        {orderStatusEnumToString(item.status)}
                                    </div>
                                </>
                            )
                        },
                        appendToLabel: <ReactDatePicker
                            onChange={(value) => {
                                if (!value) {
                                    updateFilter('from', '');
                                    updateFilter('to', '');
                                    return;
                                }

                                const from = moment(value).startOf('day').toISOString();
                                const to = moment(value).endOf('day').toISOString();

                                updateFilter('from', from);
                                updateFilter('to', to);
                            }}
                            value={filter['from'] ? moment(filter['from']).format('DD/MM/YY') : undefined}
                            selected={filter['from'] ? moment(filter['from']).toDate() : undefined}
                            dateFormat='P'
                            isClearable={true}
                            calendarStartDay={1}
                            className='border w-full px-2 rounded'
                        />
                    },
                    {
                        label: 'Bestiller',
                        appendToLabel: <InputFilter placeholder='Søg på bestiller' onChange={(val) => updateFilter('creator', val)} />,
                        cell: (order) => {
                            return (
                                <>
                                    <div>
                                        {order.createdBy?.name}
                                    </div>
                                    <div className='text-xs font-semibold'>
                                        {order.createdBy?.email}
                                    </div>
                                </>
                            )
                        }
                    },
                    {
                        label: 'Medarbejder #',
                        appendToLabel: <InputFilter placeholder='Indtast nummer' onChange={(val) => updateFilter('employeeNo', val)} />,
                        cell: (order) => order.employeeNumber
                    },
                    {
                        label: 'Produkter',
                        appendToLabel: <InputFilter placeholder='Søg produktnavn' onChange={(val) => updateFilter('productName', val)} />,
                        cell: (order) => {
                            return (
                                <ul>
                                    {
                                        order.lines.map((l, i) => <li key={i} className='py-2'>{l.quantity}x {l.productVariant.productName} ({l.productVariant.size})</li>)
                                    }
                                </ul>
                            )
                        }
                    },
                    {
                        label: 'Leveringssted',
                        appendToLabel: <InputFilter placeholder='Søg på adresse' onChange={(val) => updateFilter('address', val)} />,
                        cell: (order) => order.address.name || `${order.address.street}, ${order.address.zip} ${order.address.city}`
                    },
                    {
                        label: 'Kommentar',
                        cell: (order) => {
                            return (
                                <>
                                    {
                                        order.reason &&
                                        <div className='text-xs font-semibold'>{order.reason}</div>
                                    }

                                    {
                                        order.note &&
                                        <div>{order.note}</div>
                                    }
                                </>
                            )
                        }
                    },
                    {
                        label: '',
                        width: 80,
                        cell: (order) => {
                            if (order.status !== OrderStatusEnum.Pending && order.status !== OrderStatusEnum.Approved && order.status !== OrderStatusEnum.BackOrder) return null; // Order has not been accepted by JD yet, or order has not been effectuated
                            if (order.createdBy.id !== authStore.user?.id && authStore.role !== UserRoleEnum.ADMIN) return null; // Users can cancel own orders. Admins can cancel all

                            return (
                                <button
                                    type='button'
                                    className='border px-2 py-1 rounded'
                                    onClick={() => deleteOrder(order.id)}>
                                    Slet
                                </button>
                            )
                        }
                    }
                ]}
                onRowClicked={(item: OrderInterface) => null}
                rowStyle={(item, index) => ({
                    background: index % 2 === 0 ? '#fafafa' : undefined,
                    cursor: 'pointer'
                })}
            />

            {
                orderStore.orders?.pagination.hasNextPage &&
                <div className='text-center mt-12'>
                    <Button
                        type='button'
                        label='Indlæs flere...'
                        appearance='secondary'
                        onClick={() => orderStore.getOrders()}
                    />
                </div>
            }
        </div>
    )
})

export default OrdersTable;