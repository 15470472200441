export enum ReasonForOrderEnum {
    FIRST_TIME_ORDER = 'Førstegangsbestilling',
    WRONG_SIZE = 'Bestilt forkert str',
    REPLACEMENT = 'Erstatning',
    TEAR = 'Nedslidning',
    EXCHANGED_WRONG_DELIVERY = 'Ombytning (Forkert leveret)',
    COMPLAINT = 'Reklamation',

    // ADMIN
    RESERVE = 'Reservebeholdning',
    COLLECTION = 'Kollektionsbestilling'
}