import { action, makeObservable, observable } from 'mobx';
import { CategoryInterface } from 'types/CategoryInterface';
import { BaseStore } from './BaseStore';

class CategoryStore extends BaseStore {

    categories: Array<CategoryInterface> = [];

    constructor() {
        super();

        makeObservable(this, {
            categories: observable,

            resetStore: action,
            getCategories: action
        })
    }

    resetStore = () => {
        this.categories = [];
    }

    getCategories = () => {
        return this.get(`products/categories`, 'categories');
    }

}

export const categoryStore = new CategoryStore();