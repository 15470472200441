import { routes } from "components/Routes";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { generatePath, Outlet, useNavigate } from "react-router-dom";
import { authStore } from "stores/AuthStore";
import { loggedInContainerStore } from 'stores/LoggedInContainerStore';

const LoggedInContainer = observer(() => {
    const [isInitialized, setIsInitialized] = useState(false);
    const navigate = useNavigate();
    const user = authStore.user;

    useEffect(() => {
        if (!user) {
            if (authStore.urlpath) {
                navigate(generatePath(routes.pageLogin, { urlpath: authStore.urlpath }));
            }
        }
        setIsInitialized(true);
    }, [user, navigate])

    if (!isInitialized || !authStore.urlpath) return null;
    const fullWidth = loggedInContainerStore.fullWidth;

    return (
        <div className='flex min-h-screen'>
            <div className={`
                m-auto
                ${fullWidth ? 'mt-0' : 'mt-4'}
                ${fullWidth ? 'w-full' : 'w-[1440px]'}
            `}>
                <div className={`
                bg-white
                shadow
                rounded
                `}>
                    <Outlet />
                </div>
                <div className='text-right'>
                    {
                        !fullWidth &&
                        <button type='button' className='text-sm text-gray-400' onClick={() => loggedInContainerStore.setFullWidth(true)}>
                            Udvid &rarr;
                        </button>
                    }
                    {
                        fullWidth &&
                        <button type='button' className='text-sm text-gray-400 pr-10' onClick={() => loggedInContainerStore.setFullWidth(false)}>
                            &larr; Krymp
                        </button>
                    }
                </div>
            </div>

        </div>
    )
})

export default LoggedInContainer;