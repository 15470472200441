import { getAvailableSizesByVariants } from 'helpers/ProductHelper';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { ProductVariantInterface } from 'types/ProductVariantInterface';

interface Props {
    overwriteClassName?: string,
    placeholder?: string,
    variants: Array<ProductVariantInterface>,
    name: string
}

const SelectSize = (props: Props) => {
    const { register, unregister } = useFormContext();

    useEffect(() => {
        return () => {
            unregister(props.name)
        }
    }, []);

    const options = getAvailableSizesByVariants(props.variants);

    return (
        <select
            placeholder='Vælg str.'
            {...register(props.name)}
            className={
                props.overwriteClassName ||
                `
                border
                w-24
                pl-2
                h-9
                rounded
            `}
        >
            {
                props.placeholder &&
                <option value=''>{props.placeholder}</option>
            }
            {
                options?.map((option, index) => {
                    let label = option;
                    if (typeof label === 'string') {
                        label = label.toUpperCase(); // xs should be shown as XS etc.
                    }

                    return (
                        <option key={index} value={option}>{label}</option>
                    )
                })
            }
        </select >
    )

}

export default SelectSize;