import Button from 'components/Forms/Button';
import Form from 'components/Forms/Form';
import Input from 'components/Forms/Input';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { authStore } from 'stores/AuthStore';
import { YupInterface } from 'types/YupInterface';

const PASSWORD1_NAME = 'password1';
const PASSWORD2_NAME = 'password2';

const PageResetPassword = observer(() => {
    const [isComplete, setIsComplete] = useState(false);
    const { token } = useParams<{ token: string }>();

    const onSubmit = async (data: any) => {
        if (!token) return;

        if (data[PASSWORD1_NAME] !== data[PASSWORD2_NAME]) {
            toast('De to password er ikke ens. Du skal indtaste det samme password i begge felter.', { type: 'error', autoClose: 5000 });
            return;
        }

        try {
            await authStore.setPassword(token, data.password1);
            setIsComplete(true);
        }
        catch (error) {
            toast('Der opstod en fejl', { type: 'error' });
        }
    }

    const validation = (yup: YupInterface) => {
        return {
            [PASSWORD1_NAME]: yup.string().required('Password skal udfyldes'),
            [PASSWORD2_NAME]: yup.string().required('Password skal udfyldes'),
        }
    }

    if (isComplete) {
        return (
            <h1
                className='text-2xl font-semibold mt-16 text-center'>Dit password er blevet ændret 🥳<br /><br/>Du kan nu besøge forsiden du er blevet oplyst af FMI, og logge ind!
            </h1>
        )
    }

    return (
        <div className='
                w-[300px]
                mx-auto
                mt-16
            '>
            <Form onSubmit={onSubmit} validation={validation}>
                <h1 className='text-xl font-semibold mb-8'>Nulstil dit password her</h1>
                <p>
                    Husk at dit nye password skal være minimum 6 tegn/bogstaver langt.
                </p>
                <Input
                    className='mt-8'
                    type='password'
                    label='Nyt password'
                    name={PASSWORD1_NAME}
                />
                <Input
                    className='mt-8'
                    type='password'
                    label='Nyt password'
                    name={PASSWORD2_NAME}
                />
                <div className='text-right mt-8'>
                    <Button
                        label='Gem nyt password'
                        type='submit'
                        appearance='primary'
                    />
                </div>

            </Form>
        </div>
    )
})

export default PageResetPassword;