import { UrlPathEnum } from 'types/UrlPathEnum';

export const urlpathToString = (urlpath: UrlPathEnum) => {
    switch(urlpath) {
        case UrlPathEnum.ADMIN: return 'Administrator';
        case UrlPathEnum.DHL: return 'DHL';
        case UrlPathEnum.FORSVARET: return 'Forsvaret';
        case UrlPathEnum.ML: return 'ML';
        default: return '';
    }
}