import Input from 'components/Forms/Input';
import Header from 'components/Header';
import ModalCreate from 'components/ModalCreate';
import { adminRoutes } from 'components/Routes/AdminRoutes';
import SidebarMenuContainerAdmin, { ActiveMenuEnum } from 'components/SidebarMenuContainerAdmin';
import Table from 'components/Table';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { addressStore } from 'stores/AddressStore';
import { newsStore } from 'stores/NewsStore';
import { AddressInterface } from 'types/AddressInterface';
import { NewsInterface } from 'types/NewsInterface';

const NAME_NAME = 'name';
const STREET_NAME = 'street';
const ZIP_NAME = 'zip';
const CITY_NAME = 'city';


const PageAddresses = observer(() => {
    const [searchQuery, setSearchQuery] = useState('')
    const navigate = useNavigate();

    useEffect(() => {
        addressStore.getAddresses();

        return () => {
            addressStore.resetStore();
        }
    }, [])

    const onCreate = (data: any) => {
        addressStore.createAddress(
            data[NAME_NAME],
            data[STREET_NAME],
            data[ZIP_NAME],
            data[CITY_NAME]
        )
    }

    const addresses = _.filter(addressStore.addresses, (a) => {
        if (!searchQuery) return true;

        return (
            (a.name && a.name?.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1)
            || a.street?.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1
            || a.zip?.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1
            || a.city?.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1
        )
    })

    return (
        <div>
            <Header />
            <SidebarMenuContainerAdmin
                activeMenuItem={ActiveMenuEnum.ADDRESSES}>
                <div className='flex justify-between items-center mb-6'>
                    <input
                        onChange={(e) => setSearchQuery(e.currentTarget.value)}
                        type="text"
                        className='border mb-2 py-2 px-4 rounded w-64'
                        placeholder='Søg på navn eller adresse...'
                    />
                    <ModalCreate
                        onSubmit={onCreate}
                        validation={(yup) => ({
                            name: yup.string().required('Navn er påkrævet'),
                            street: yup.string().required('Adresse er påkrævet'),
                            zip: yup.string().required('Postnummer er påkrævet'),
                            city: yup.string().required('By er påkrævet')
                        })}
                        modalTitle='Opret ny adresse'
                        buttonLabel='Ny adresse'
                        modalSubmitLabel='Opret adresse'
                        formElements={
                            <>
                                <Input
                                    label='Navn'
                                    name={NAME_NAME}
                                />

                                <Input
                                    label='Adresse'
                                    name={STREET_NAME}
                                    className='mt-6'
                                />

                                <div className='flex mt-6'>
                                    <Input
                                        label='Postnummer'
                                        name={ZIP_NAME}
                                        className='mr-2'
                                    />

                                    <Input
                                        label='By'
                                        name={CITY_NAME}
                                    />
                                </div>
                            </>
                        }
                    />
                </div>

                <Table
                    data={addresses}
                    render={[
                        {
                            label: 'Navn',
                            sortProperty: (item) => item.name,
                            cell: (item) => item.name
                        },
                        {
                            label: 'Adresse',
                            cell: (item) => `${item.street}, ${item.zip} ${item.city}`
                        }
                    ]}
                    onRowClicked={(item: AddressInterface) => navigate(generatePath(adminRoutes.pageAddressDetails, { id: item.id }))}
                />

            </SidebarMenuContainerAdmin>
        </div>
    )
})

export default PageAddresses;