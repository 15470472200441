import Button from 'components/Forms/Button';
import Form from 'components/Forms/Form';
import Input from 'components/Forms/Input';
import Header from 'components/Header';
import { adminRoutes } from 'components/Routes/AdminRoutes';
import SelectUserRole from 'components/SelectUserRole';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { authStore } from 'stores/AuthStore';
import { groupStore } from 'stores/GroupStore';
import { userDetailsStore } from 'stores/UserDetailsStore';
import { UserRoleEnum } from 'types/UserRoleEnum';

const NAME_NAME = 'name';
const EMAIL_NAME = 'email';
const ROLE_NAME = 'role';
const SEND_PRODUCT_DISPATCH_COUNT_REPORT = 'SendProductDispatchCountReport';
const SEND_PRODUCT_STOCK_REPORT = 'sendProductStockReport';



const PageUserDetails = observer(() => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    useEffect(() => {
        userDetailsStore.getUser(id!);
        groupStore.getGroups();

        return () => {
            userDetailsStore.resetStore();
            groupStore.resetStore();
        }
    }, [id])

    const onSubmit = async (data: any) => {

        Object.keys(data.groups || {}).forEach(async (groupId) => {
            const user = userDetailsStore.user!;
            const isSelected = data.groups[groupId];
            const wasSelected = _.some(user.groups, g => g.id === groupId);

            if (isSelected && !wasSelected) {
                await userDetailsStore.addUserToGroup(user.id, groupId);
            }

            if (!isSelected && wasSelected) {
                await userDetailsStore.removeUserFromGroup(user.id, groupId);
            }

        })

        const user = userDetailsStore.user!;

        user.name = data[NAME_NAME];
        user.email = data[EMAIL_NAME];
        user.role = data[ROLE_NAME];
        user.sendProductStockReport = data[SEND_PRODUCT_STOCK_REPORT];
        user.sendProductDispatchCountReport = data[SEND_PRODUCT_DISPATCH_COUNT_REPORT];

        await userDetailsStore.updateUser(user);
        navigate(adminRoutes.pageUsers);
    }

    const onDelete = async (userId: string) => {
        if (!window.confirm("Er du sikker på at du vil slette denne bruger?")) return;

        await userDetailsStore.deleteUser(userId);
        navigate(adminRoutes.pageUsers);
    }

    const onResendPassword = async (userId: string) => {
        if (!window.confirm("Er du sikker på at du vil sende et helt nyt password til denne bruger?")) return;

        await authStore.resendPassword([userId]);
        toast('Bruger har modtaget et nyt password på mail', { type: 'success' });

    }

    const user = userDetailsStore.user;
    const groups = groupStore.groups;
    if (!user) return null;

    return (
        <div>
            <Header />
            <Form
                onSubmit={onSubmit}
                formOptions={{
                    defaultValues: {
                        [NAME_NAME]: user.name,
                        [EMAIL_NAME]: user.email,
                        [ROLE_NAME]: user.role,
                        [SEND_PRODUCT_DISPATCH_COUNT_REPORT]: user.sendProductDispatchCountReport,
                        [SEND_PRODUCT_STOCK_REPORT]: user.sendProductStockReport,
                    }
                }}
                validation={(yup) => ({
                    [NAME_NAME]: yup.string().required('Navn er påkrævet'),
                    [EMAIL_NAME]: yup.string().required('Email er påkrævet'),
                    [ROLE_NAME]: yup.string().required('Rolle er påkrævet')
                })}>
                <div className='flex'>
                    <div className='flex-1'>
                        <div className='my-16 w-72 mx-auto'>
                            <h1 className='text-2xl mb-8'>Brugerdetaljer</h1>

                            <Input
                                label='Navn'
                                name={NAME_NAME}
                            />

                            <Input
                                label='Email'
                                name={EMAIL_NAME}
                                className='mt-6'
                            />

                            <h2 className='mt-12 font-semibold'>
                                CSV rapporter på mail
                            </h2>

                            <Input
                                type='checkbox'
                                label='Bevægelser (Halvårlig)'
                                name={SEND_PRODUCT_DISPATCH_COUNT_REPORT}
                                className='mt-6'
                            />

                            <Input
                                type='checkbox'
                                label='Beholdning (Månedlig)'
                                name={SEND_PRODUCT_STOCK_REPORT}
                                className='mt-6'
                            />

                        </div >
                    </div>
                    <div className='flex-1 border-l'>
                        <div className='my-16 w-72 mx-auto'>
                            <div className='mb-8'>
                                <h1 className='text-2xl mb-4'>Adgangsrettigheder</h1>
                            </div>

                            <SelectUserRole
                                name={ROLE_NAME}
                                label='Privilegier'
                                className='mt-6'
                            />
                            {
                                user.role !== UserRoleEnum.ADMIN && groups.map((g, i) => {
                                    return (
                                        <Input
                                            type='checkbox'
                                            key={i}
                                            label={g.name}
                                            name={`groups[${g.id}]`}
                                            defaultChecked={_.some(user.groups, ug => ug.id === g.id)}
                                            className='mt-6'
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div >

                <div className='py-8 text-center border-t'>
                    <Button
                        type='button'
                        appearance='secondary'
                        label='Tilbage'
                        className='mr-4'
                        onClick={() => navigate(adminRoutes.pageUsers)}
                    />
                    <Button
                        type='submit'
                        appearance='primary'
                        label='Gem ændringer'
                    />
                </div>

                <div className='text-right p-4'>
                    <button
                        type='button'
                        onClick={() => onResendPassword(user.id)}
                        className='underline text-sm text-gray-400 mr-6'>
                        Send nyt password til bruger
                    </button>

                    <button
                        type='button'
                        onClick={() => onDelete(user.id)}
                        className='underline text-sm text-gray-400'>
                        Slet bruger
                    </button>
                </div>
            </Form>
        </div >
    )

})

export default PageUserDetails;