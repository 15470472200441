import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useEffect } from 'react';
import { newsStore } from 'stores/NewsStore';

const NewsBox = observer(() => {

    useEffect(() => {
        newsStore.getNews();

        return () => {
            newsStore.resetStore();
        }
    }, [])

    const news = newsStore.news;
    if (news.length === 0) return null;

    return (
        <div className='w-[350px] border-l p-4'>
            {
                news.map((item, index) => {
                    return (
                        <div 
                            key={index}
                            className='mb-12'>
                            <div className='flex mb-2 border-b pb-2'>
                                <h2 className='flex-1 font-semibold'>{item.title}</h2>
                                <span className='text-xs'>{moment(item.createdOn).format('DD/MM/YY')}</span>
                            </div>
                            <p className='whitespace-pre-line'>
                                {item.text}
                            </p>
                        </div>
                    )
                })
            }

        </div>
    )

})

export default NewsBox;