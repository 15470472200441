import Header from 'components/Header';
import SidebarMenuContainerAdmin, { ActiveMenuEnum } from 'components/SidebarMenuContainerAdmin';
import 'react-datepicker/dist/react-datepicker.css';
import OrdersTable from 'components/OrdersTable';

const PageAdminBackOrders = () => {

    return (
        <div>
            <Header />
            <SidebarMenuContainerAdmin
                activeMenuItem={ActiveMenuEnum.BACK_ORDERS}>
                
                <OrdersTable backOrders={true} />
            </SidebarMenuContainerAdmin>
        </div>
    )
}

export default PageAdminBackOrders;