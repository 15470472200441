import Button from 'components/Forms/Button';
import Form from 'components/Forms/Form';
import Input from 'components/Forms/Input';
import Header from 'components/Header';
import { adminRoutes } from 'components/Routes/AdminRoutes';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { addressDetailsStore } from 'stores/AddressDetailsStore';

const NAME_NAME = 'name';
const STREET_NAME = 'street';
const ZIP_NAME = 'zip';
const CITY_NAME = 'city';

const PageAddressDetails = observer(() => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    useEffect(() => {
        addressDetailsStore.getaddress(id!);

        return () => {
            addressDetailsStore.resetStore();
        }
    }, [id])

    const onSubmit = async (data: any) => {
        const address = addressDetailsStore.address!;

        address.name = data[NAME_NAME];
        address.street = data[STREET_NAME];
        address.zip = data[ZIP_NAME];
        address.city = data[CITY_NAME];

        await addressDetailsStore.updateAddress(address)
        navigate(adminRoutes.pageAddresses);
    }

    const onDelete = async (addressId: string) => {
        if (!window.confirm("Er du sikker på at du vil slette denne adresse?")) return;

        await addressDetailsStore.deleteAddress(addressId);
        navigate(adminRoutes.pageAddresses);
    }

    const address = addressDetailsStore.address;
    if (!address) return null;

    return (
        <div>
            <Header />
            <div className='my-16 w-72 mx-auto'>
                <h1 className='text-2xl mb-12'>Ret adresse</h1>
                <Form
                    onSubmit={onSubmit}
                    formOptions={{
                        defaultValues: {
                            [NAME_NAME]: address.name,
                            [STREET_NAME]: address.street,
                            [ZIP_NAME]: address.zip,
                            [CITY_NAME]: address.city,
                        }
                    }}
                    validation={(yup) => ({
                        name: yup.string().nullable().required('Navn er påkrævet'),
                        street: yup.string().required('Adresse er påkrævet'),
                        zip: yup.string().required('Postnummer er påkrævet'),
                        city: yup.string().required('By er påkrævet')
                    })}>

                    <Input
                        label='Navn'
                        name={NAME_NAME}
                    />

                    <Input
                        label='Adresse'
                        name={STREET_NAME}
                        className='mt-6'
                    />

                    <div className='flex mt-6'>
                        <Input
                            label='Postnummer'
                            name={ZIP_NAME}
                            className='mr-2'
                        />

                        <Input
                            label='By'
                            name={CITY_NAME}
                        />
                    </div>

                    <div className='flex justify-around mt-12'>
                        <Button
                            type='button'
                            appearance='secondary'
                            label='Tilbage'
                            onClick={() => navigate(adminRoutes.pageAddresses)}
                        />
                        <Button
                            type='submit'
                            appearance='primary'
                            label='Gem ændringer'
                        />
                    </div>


                </Form>
            </div>
            <div className='text-right p-4'>
                <button
                    type='button'
                    onClick={() => onDelete(address.id)}
                    className='underline text-sm text-gray-400'>
                    Slet adresse
                </button>
            </div>

        </div>
    )

})

export default PageAddressDetails;