import { routes } from "components/Routes";
import { useNavigate } from "react-router-dom";
import { cartStore } from "stores/CartStore";
import { placeOrderStore } from "stores/PlaceOrderStore";

const BackToFrontpageButton = () => {
    const navigate = useNavigate();

    const onClick = () => {
        placeOrderStore.resetStore();
        cartStore.resetStore();
        navigate(routes.pageFront);
    }

    return (
        <button
            onClick={onClick}
            className='
                block
                py-2
                px-4
                border
                rounded
                mt-[-9px]
                mb-6
                '>
            &larr; Afbryd bestilling
        </button>
    )
}

export default BackToFrontpageButton;