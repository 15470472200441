import { action, makeObservable, observable } from "mobx";
import { CartItemInterface } from "types/CartItemInterface";
import { ClothesSizeEnum } from "types/ClothesSizeEnum";
import _ from 'lodash';
import { BaseStore } from './BaseStore';
import request from 'helpers/Request';

class CartStore extends BaseStore {

    items: Array<CartItemInterface> = [];

    constructor() {
        super('CartStore');

        makeObservable(this, {
            items: observable,

            resetStore: action,
            addOrUpdateItem: action,
            removeItem: action,
            placeOrder: action
        });

        this.initSessionStorage(this, ['items']);
    }

    resetStore = () => {
        this.items = [];
    }

    addOrUpdateItem = (productId: string, variantId: string, size: ClothesSizeEnum, quantity: number, maxQty?: number) => {
        const res = _.find(this.items, i => i.variantId === variantId);
        if (res) {
            res.selectedSize = size;
            res.quantity = quantity;
        }
        else {
            this.items.push({
                productId: productId,
                variantId: variantId,
                selectedSize: size,
                quantity: quantity,
                maxQty: maxQty
            })
        }
    }

    removeItem = (productId: string, variantId: string) => {
        this.items = _.filter(this.items, i => i.variantId !== variantId);
    }

    placeOrder = async (employeeNumber: string | null, reason: string | null, note: string, addressId: string, variants: Array<{ variantId: string, quantity: number }>) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.post(`orders`, {
                    employeeNumber: employeeNumber,
                    reason: reason,
                    note: note,
                    address: { id: addressId },
                    lines: variants.map((variant) => ({
                        quantity: variant.quantity,
                        productVariant: { id: variant.variantId }
                    }))
                })

                if (res.data) {
                    resolve(res.data);
                } else reject();
            }
            catch (error) {
                reject(error);
            }
        })
    }
}

export const cartStore = new CartStore();