export const buildQuery = (baseUrl: string, queryParams?: { [key: string]: string }) => {
    const params = new URLSearchParams();

    if (queryParams) {
        Object.keys(queryParams).forEach((key) => {
            params.append(key, queryParams[key]);
        })
    }

    let url = baseUrl;
    if (Array.from(params).length > 0) {
        const hasQueryParameters = baseUrl.indexOf('?') !== -1;
        const firstSeperator = hasQueryParameters ? '&' : '?';
        url += firstSeperator;
    }

    return url + params.toString();
}