import Header from 'components/Header';
import SidebarMenuContainerAdmin, { ActiveMenuEnum } from 'components/SidebarMenuContainerAdmin';
import 'react-datepicker/dist/react-datepicker.css';
import _ from 'lodash';
import OrdersTable from 'components/OrdersTable';

const PageAdminOrders = () => {

    return (
        <div>
            <Header />
            <SidebarMenuContainerAdmin
                activeMenuItem={ActiveMenuEnum.ORDERS}>
                
                <OrdersTable />
            </SidebarMenuContainerAdmin>
        </div>
    )
}

export default PageAdminOrders;