import Form from 'components/Forms/Form';
import Input from 'components/Forms/Input';
import _ from 'lodash';
import { ProductVariantInterface } from 'types/ProductVariantInterface';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import { productDetailsStore } from 'stores/ProductDetailsStore';
import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import "react-datepicker/dist/react-datepicker.css";

interface Props {
    productId: string
}

const ExpectedInStock = observer((props: Props) => {

    const { productId } = props;
    useEffect(() => {
        productDetailsStore.getVariants(productId);
    }, [productId])

    const updateExpectedInStockDate = async (variant: ProductVariantInterface, dateValue: string | null) => {
        variant.expectedInStockDate = dateValue;
        await productDetailsStore.updateVariant(props.productId, variant);
        toast(`Forventet dato for str. ${variant.size} gemt`, { type: 'success' });
    }

    const updateIsIgnored = async (variant: ProductVariantInterface, value: boolean) => {
        variant.ignore = value;
        await productDetailsStore.updateVariant(props.productId, variant);
        toast(`Visning af str. ${variant.size} i shop ændret`, { type: 'success' });
    }

    const variants = productDetailsStore.variants;
    const variantsOutOfStock = _.filter(variants, v => v.quantity < 1);

    return (
        <div className='flex-1 border-l'>
            <div className='my-16 w-96 mx-auto'>
                <div className='mb-12'>
                    <h1 className='text-2xl mb-4'>Salgsbare størrelser i webshop</h1>
                    <p>
                        Klik til og fra hvilke størrelser der skal være mulige at sælge i webshoppen, såfremt de er på lager.
                    </p>

                    {
                        variants.map((variant, index) => {
                            return (
                                <div key={index}>
                                    <div className='flex my-6'>
                                        <p className='w-[50px] font-semibold text-right pr-4'>
                                            {variant.size}
                                        </p>
                                        <input checked={!variant.ignore} type='checkbox' onChange={(e) => updateIsIgnored(variant, !e.currentTarget.checked as any)} />
                                        <p className='ml-6 text-sm'>
                                            ({variant.quantity} på lager)
                                        </p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>


                <h1 className='text-2xl mb-4'>Størrelser i restordre</h1>
                {
                    variantsOutOfStock.length === 0 &&
                    <p>
                        Der er ingen størrelser i restordre pt.
                    </p>
                }

                {
                    variantsOutOfStock.length > 0 &&
                    <p>
                        Der er pt. {variantsOutOfStock.length} størrelser i restordre. Herunder kan du sætte den forventede dato de er tilbage på lager.
                    </p>
                }

                {
                    variantsOutOfStock.map((variant, index) => {
                        return (
                            <div key={index}>
                                <div className='flex my-6'>
                                    <p className='w-[50px] font-semibold text-right pr-4'>
                                        {variant.size}
                                    </p>
                                    <ReactDatePicker
                                        onChange={async (value) => {
                                            if (!value) {
                                                updateExpectedInStockDate(variant, null);
                                                return;
                                            }
                                            const dateStrVal = moment(value).startOf('day').toISOString();
                                            updateExpectedInStockDate(variant, dateStrVal);
                                        }}
                                        value={variant.expectedInStockDate ? moment(variant.expectedInStockDate).format('DD/MM/YY') : undefined}
                                        dateFormat='P'
                                        placeholderText='Forventet på lager (dato)'
                                        isClearable={true}
                                        selected={variant.expectedInStockDate ? moment(variant.expectedInStockDate).toDate() : undefined}
                                        calendarStartDay={1}
                                        className='border w-full px-2 rounded'

                                    />
                                </div>
                            </div>
                        )
                    })
                }
            </div >
        </div>
    )
})

export default ExpectedInStock;