interface Props {
    sidebarMenuContent: React.ReactNode,
    rightColumn?: React.ReactNode,
    children: React.ReactNode
}

const SidebarMenuContainer = (props: Props) => {

    return (
        <div className='flex my-5'>
            <div className='relative'>
                <div
                    style={{ borderRight: '1px solid #eaeaea' }}
                    className='
                    w-[280px]
                    sticky top-0
                    text-sm
                    pl-10
                    pb-10
                '>
                    <ul className='pt-5'>
                        {props.sidebarMenuContent}
                    </ul>
                </div>
            </div>

            <div className='
                    mx-10
                    my-5
                    flex-1
                    text-sm
                    min-w-[500px]
                '>
                {props.children}
            </div>
            {
                props.rightColumn
            }
        </div>
    )

}

export default SidebarMenuContainer;