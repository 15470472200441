import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
    overwriteClassName?: string,
    className?: string,
    placeholder?: string,
    maxQuantity: number,
    name: string
}

const SelectQuantity = (props: Props) => {
    const { register, unregister } = useFormContext();

    useEffect(() => {
        return () => {
            unregister(props.name)
        }
    }, []);


    let options: Array<React.ReactNode> = [];

    for (let i = 1; i <= props.maxQuantity; i++) {
        options.push(
            <option key={i} value={i}>{i}</option>
        )
    }

    return (
        <select
            placeholder='Vælg str.'
            {...register(props.name)}
            className={
                props.overwriteClassName ||
                `
                border
                w-16
                pl-2
                h-9
                rounded
                ${props.className || ''}
            `}
        >
            {
                props.placeholder &&
                <option value=''>{props.placeholder}</option>
            }
            {options}
        </select >
    )

}

export default SelectQuantity;