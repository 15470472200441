import Button from 'components/Forms/Button';
import Form from 'components/Forms/Form';
import Input from 'components/Forms/Input';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { authStore } from 'stores/AuthStore';
import { YupInterface } from 'types/YupInterface';

const EMAIL_NAME = 'email';

const PageForgotPassword = observer(() => {
    const [isComplete, setIsComplete] = useState(false);

    const onSubmit = async (data: any) => {
        try {
            const email = data[EMAIL_NAME];
            await authStore.resetPassword(email);
            setIsComplete(true);
        }
        catch (error) {
            toast('Der opstod en fejl ved nulstilling af passord', { type: 'error' });
        }
    }

    const validation = (yup: YupInterface) => {
        return {
            [EMAIL_NAME]: yup.string().required('Email skal udfyldes'),
        }
    }

    if (isComplete) {
        return (
            <h1
                className='text-4xl font-semibold mt-16 text-center'>Vi har sendt dig en mail!&nbsp;&nbsp;&nbsp;📨
            </h1>
        )
    }

    return (
        <div className='
                w-[300px]
                mx-auto
                mt-16
            '>
            <Form onSubmit={onSubmit} validation={validation}>
                <h1 className='text-xl font-semibold mb-8'>Glemt dit password?</h1>
                <p>Udfyld din email herunder. Så sender vi dig en mail med et link, hvor du kan sætte et nyt password.</p>
                <Input
                    className='mt-8'
                    label='Email'
                    name={EMAIL_NAME}
                />
                <div className='text-right mt-8'>

                    <Button
                        label='Nulstil password'
                        type='submit'
                        appearance='primary'
                    />
                </div>

            </Form>
        </div>
    )
})

export default PageForgotPassword;