import { productSexToString } from 'helpers/ProductHelper';
import _ from 'lodash';
import { action, computed, makeObservable, observable } from "mobx";
import { ProductInterface } from "types/ProductInterface";
import { ProductSexEnum } from 'types/ProductSexEnum';
import { BaseStore } from './BaseStore';

class ProductStore extends BaseStore {

    products: Array<ProductInterface> = [];

    filteredQuery: string | null = null;


    constructor() {
        super();

        makeObservable(this, {
            products: observable,
            filteredQuery: observable,

            resetStore: action,
            setFilteredQuery: action,
            getProducts: action,
            createProduct: action,

            filteredProducts: computed
        });
    }

    resetStore = () => {
        this.products = [];
    }

    setFilteredQuery = (value: string | null) => {
        this.filteredQuery = value;
    }

    getProducts = () => {
        return this.get(`products`, 'products');
    }

    createProduct = (name: string, categoryId: string, sex: ProductSexEnum, color: string) => {
        return this.post(`products`, {
            name: name,
            category: { id: categoryId },
            sex: sex,
            color: color
        }, this.products);
    }

    get filteredProducts() {
        let products = this.products;
        const query = this.filteredQuery?.replace(',', '').split(' ');

        if (this.filteredQuery) {
            products = products.filter((p) => {
                return _.every(query, (q) => {
                    const searchVal = q.toLowerCase();
                    const variantSkuIdentifier = p.variants[0]?.sku?.split('-')[0] || null;

                    return (
                        (searchVal.length === 1 && p.sex !== null && productSexToString(p.sex).toLowerCase() === searchVal) ||
                        (searchVal.length === 4 && variantSkuIdentifier !== null && variantSkuIdentifier === searchVal) ||
                        (searchVal.length > 1 && p.name !== null && p.name.toLowerCase().indexOf(searchVal) !== -1) ||
                        (searchVal.length > 1 && p.color !== null && p.color.toLowerCase() === searchVal)
                    )
                }) || (this.filteredQuery!.length > 2 && p.description?.toLowerCase().indexOf(this.filteredQuery!) !== -1)
            });
        }

        return products;
    }
}

export const productStore = new ProductStore();