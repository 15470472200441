export enum ClothesSizeEnum {
    XS = 'XS',
    S = 'S',
    M = 'M',
    L = 'L',
    XL = 'XL',
    EIGHT = '8',
    EIGHT_HALF = '85',
    NINE = '9',
    NINE_HALF = '95',
    TEN = '10'
}