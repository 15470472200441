import request from 'helpers/Request';
import { action, makeObservable, observable } from 'mobx';
import { EmployeeInterface } from 'types/EmployeeInterface';
import { BaseStore } from './BaseStore';

class SelectEmployeeStore extends BaseStore {

    employees: Array<EmployeeInterface> = [];

    constructor() {
        super();

        makeObservable(this, {
            employees: observable,

            resetStore: action,
            getEmployees: action
        })
    }

    resetStore = () => {
        this.employees = [];
    }

    getEmployees = (searchEmployeeNumber: string): Promise<Array<EmployeeInterface>> => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.get(`employees?byNumber=${searchEmployeeNumber}&pagenumber=1&pagesize=10`);
                const result = res.data?.items || [];
                this.employees = result;
                resolve(result);
            }
            catch(error) {
                reject(error);
            }
        })
    }

}

export const selectEmployeeStore = new SelectEmployeeStore();