import { action, makeObservable, observable } from 'mobx';
import { AddressInterface } from 'types/AddressInterface';
import { BaseStore } from './BaseStore';

class AddressDetailsStore extends BaseStore {

    address: AddressInterface | null = null;

    constructor() {
        super();

        makeObservable(this, {
            address: observable,

            resetStore: action,
            getaddress: action,
            updateAddress: action,
            deleteAddress: action
        })
    }

    resetStore = () => {
        this.address = null;
    }

    getaddress = (id: string) => {
        return this.get(`addresses/${id}`, 'address');
    }

    updateAddress = (address: AddressInterface) => {
        return this.put(`addresses/${address.id}`, address, 'address');
    }

    deleteAddress = (id: string) => {
        return this.delete(`addresses/${id}`, id, this.address);
    }
}

export const addressDetailsStore = new AddressDetailsStore();