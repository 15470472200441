import Button from 'components/Forms/Button';
import Form from 'components/Forms/Form';
import Input from 'components/Forms/Input';
// import { Input } from '@nordicodeaps/nordicode-core-web-package';
import logoSrc from 'components/Header/logo.png';
import { routes } from 'components/Routes';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { authStore } from 'stores/AuthStore';
import { UrlPathEnum } from 'types/UrlPathEnum';
import { YupInterface } from 'types/YupInterface';

const USERNAME_NAME = 'username';
const PASSWORD_NAME = 'password';

const PageLogin = observer(() => {
    const [isValidUrlpath, setIsValidUrlpath] = useState(false);
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const navigate = useNavigate();
    const { urlpath } = useParams<{ urlpath: UrlPathEnum }>();

    useEffect(() => {
        if (authStore.isLoggedIn) {
            navigate(routes.pageFront);
        }
    }, [])

    useEffect(() => {
        const pathExists = Object.values(UrlPathEnum).includes(urlpath as UrlPathEnum);
        setIsValidUrlpath(pathExists);
    }, [urlpath])

    if (!isValidUrlpath) return null;

    const onSubmit = async (data: any) => {
        setIsLoggingIn(true);
        const username = data[USERNAME_NAME];
        const password = data[PASSWORD_NAME];
        try {
            await authStore.authenticate(username, password, urlpath as UrlPathEnum)
            setIsLoggingIn(false);
            navigate(routes.pageFront);
        }
        catch (error) {
            toast('Ugyldigt brugernavn eller adgangskode', { type: 'error' });
            setIsLoggingIn(false);
        }
    }

    const validation = (yup: YupInterface) => {
        return {
            [USERNAME_NAME]: yup.string().required('Brugernavn skal udfyldes'),
            [PASSWORD_NAME]: yup.string().required('Password skal udfyldes')
        }
    }

    return (
        <div className='
                w-[300px]
                mx-auto
                mt-16
            '>
            <img src={logoSrc} alt='Forsvarsministeret - Materiel og Indkøbsstyrelsen' width={350} />
            <Form onSubmit={onSubmit} validation={validation}>
                <Input
                    className='mt-8'
                    label='Email'
                    name={USERNAME_NAME}
                />
                <Input
                    type='password'
                    className='mt-6'
                    label='Password'
                    name={PASSWORD_NAME}
                />
                <div className='text-right mt-8'>
                    <Button
                        label={isLoggingIn ? 'Logger ind...' : 'Log ind'}
                        type='submit'
                        appearance={isLoggingIn ? 'secondary' : 'primary'}
                    />
                </div>

            </Form>

            <div className='mt-32 text-center border-t pt-8'>
                <button 
                    type='button'
                    onClick={() => navigate(routes.pageForgotPassword)}
                    className='text-sm text-gray-500'>Glemt dit password?</button>
            </div>
        </div>
    )
})

export default PageLogin;