import Button from 'components/Forms/Button';
import Form from 'components/Forms/Form';
import Input from 'components/Forms/Input';
import Textarea from 'components/Forms/Textarea';
import Header from 'components/Header';
import { adminRoutes } from 'components/Routes/AdminRoutes';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { newsDetailsStore } from 'stores/NewsDetailsStore';

const TITLE_NAME = 'title';
const TEXT_NAME = 'text';

const PageNewsDetails = observer(() => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    useEffect(() => {
        newsDetailsStore.getNews(id!);

        return () => {
            newsDetailsStore.resetStore();
        }
    }, [id])

    const onSubmit = async (data: any) => {
        const news = newsDetailsStore.news;
        if (!news) return;

        news.title = data[TITLE_NAME];
        news.text = data[TEXT_NAME];

        await newsDetailsStore.updateNews(news);
        navigate(adminRoutes.pageNews);
    }

    const onDelete = async (id: string) => {
        if (!window.confirm("Er du sikker på at du vil slette denne nyhed?")) return;

        await newsDetailsStore.deleteNews(id);
        navigate(adminRoutes.pageNews);
    }

    const news = newsDetailsStore.news;
    if (!news) return null;

    return (
        <div>
            <Header />
            <div className='my-16 w-72 mx-auto'>
                <h1 className='text-2xl mb-12'>Ret nyhed</h1>
                <Form
                    onSubmit={onSubmit}
                    formOptions={{
                        defaultValues: {
                            [TITLE_NAME]: news.title,
                            [TEXT_NAME]: news.text,
                        }
                    }}
                    validation={(yup) => ({
                        [TITLE_NAME]: yup.string().required('Titel er påkrævet'),
                        [TEXT_NAME]: yup.string().required('Beskrivelse er påkrævet'),
                    })}>

                    <Input
                        label='Titel'
                        name={TITLE_NAME}
                    />


                    <Textarea
                        label='Beskrivelse'
                        name={TEXT_NAME}
                        className='mt-6'
                    />

                    <div className='flex justify-around mt-12'>
                        <Button
                            type='button'
                            appearance='secondary'
                            label='Tilbage'
                            onClick={() => navigate(adminRoutes.pageNews)}
                        />
                        <Button
                            type='submit'
                            appearance='primary'
                            label='Gem ændringer'
                        />
                    </div>
                </Form>
            </div >
            <div className='text-right p-4'>
                <button
                    type='button'
                    onClick={() => onDelete(news.id)}
                    className='underline text-sm text-gray-400'>
                    Slet nyhed
                </button>
            </div>
        </div >
    )

})

export default PageNewsDetails;