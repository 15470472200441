import Select from 'components/Forms/Select'
import { productSexToString } from 'helpers/ProductHelper'
import { ProductSexEnum } from 'types/ProductSexEnum'

interface Props {
    label: string,
    name: string,
    className?: string
}

const SelectSex = (props: Props) => {

    return (
        <Select
            className={props.className}
            name={props.name}
            label={props.label}
            options={[
                {
                    id: ProductSexEnum.MALE.toString(),
                    value: productSexToString(ProductSexEnum.MALE)
                },
                {
                    id: ProductSexEnum.FEMALE.toString(),
                    value: productSexToString(ProductSexEnum.FEMALE)
                },
            ]}
        />
    )

}

export default SelectSex;