import LoggedInContainer from 'components/LoggedInContainer';
import PageAddressDetails from 'components/Pages/Admin/PageAddressDetails';
import PageAddresses from 'components/Pages/Admin/PageAddresses';
import PageNews from 'components/Pages/Admin/PageNews';
import PageProductDetails from 'components/Pages/Admin/PageProductDetails';
import PageProducts from 'components/Pages/Admin/PageProducts';
import PageUserDetails from 'components/Pages/Admin/PageUserDetails';
import PageUsers from 'components/Pages/Admin/PageUsers';
import PageLogin from 'components/Pages/PageLogin';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { routes } from '.';
import PageAdminOrders from 'components/Pages/Admin/PageAdminOrders';
import PageNewsDetails from 'components/Pages/Admin/PageNewsDetails';
import PageUserProfile from 'components/Pages/PageUserProfile';
import PageAdminBackOrders from 'components/Pages/Admin/PageAdminBackOrders';

export const adminRoutes = {
    pageNews: '/',
    pageNewsDetails: '/news/:id',
    pageOrders: '/orders',
    pageBackOrders: '/orders/backorders',
    pageUsers: '/users',
    pageUserDetails: '/users/:id',
    pageAddresses: '/addresses',
    pageAddressDetails: '/addresses/:id',
    pageProducts: '/products',
    pageProductDetails: '/products/:id',
    pageUserProfile: '/user-profile'
}

const AdminRoutes: React.FC<any> = () => {

    return (
        <Routes>
            <Route path={routes.pageLogin} element={<PageLogin />} />
            <Route element={<LoggedInContainer />}>
                <Route path={adminRoutes.pageNews} element={<PageNews />} />
                <Route path={adminRoutes.pageNewsDetails} element={<PageNewsDetails />} />
                <Route path={adminRoutes.pageOrders} element={<PageAdminOrders />} />
                <Route path={adminRoutes.pageBackOrders} element={<PageAdminBackOrders />} />
                <Route path={adminRoutes.pageUsers} element={<PageUsers />} />
                <Route path={adminRoutes.pageUserDetails} element={<PageUserDetails />} />
                <Route path={adminRoutes.pageAddresses} element={<PageAddresses />} />
                <Route path={adminRoutes.pageAddressDetails} element={<PageAddressDetails />} />
                <Route path={adminRoutes.pageProducts} element={<PageProducts />} />
                <Route path={adminRoutes.pageProductDetails} element={<PageProductDetails />} />
                <Route path={routes.pageUserProfile} element={<PageUserProfile />} />
            </Route>
        </Routes>
    )

}

export default AdminRoutes;