import Button from 'components/Forms/Button';
import Form from 'components/Forms/Form';
import Input from 'components/Forms/Input';
import { observer } from 'mobx-react-lite';
import { productStore } from 'stores/ProductStore';

const ProductFilter = observer(() => {

    return (
        <Form onSubmit={(data) => productStore.setFilteredQuery(data.search)}>
            <div className='flex items-center mt-8 mb-4'>
                {
                    !productStore.filteredQuery &&
                    <Input
                        placeholder='Søg på produktets navn, køn (M/K) eller farve...'
                        className='flex-1'
                        name='search'
                    />
                }

                {
                    productStore.filteredQuery &&
                    <div className='border p-2 w-full rounded-md bg-gray-100 flex-1'>
                        <span className='text-gray-600'>Du har filtreret på:</span> <span className='font-semibold'>{productStore.filteredQuery}</span>
                    </div>
                }

                <div style={{ marginTop: '-3px' }}>
                    {
                        !productStore.filteredQuery &&
                        <Button
                            className='ml-2'
                            type='submit'
                            label='Filtrér'
                            appearance='primary'
                        />
                    }

                    {
                        productStore.filteredQuery &&
                        <Button
                            onClick={() => productStore.setFilteredQuery(null)}
                            className='ml-2'
                            type='button'
                            label='Nulstil filtrering'
                            appearance='secondary'
                        />
                    }
                </div>
            </div>

        </Form>
    )

})

export default ProductFilter;