import _ from 'lodash';
import { authStore } from 'stores/AuthStore';
import { placeOrderStore } from 'stores/PlaceOrderStore';
import { ClothesSizeEnum } from "types/ClothesSizeEnum"
import { OrderStatusEnum } from 'types/OrderStatusEnum';
import { ProductSexEnum } from 'types/ProductSexEnum';
import { ProductVariantInterface } from 'types/ProductVariantInterface';
import { ReasonForOrderEnum } from 'types/ReasonForOrderEnum';
import { UserRoleEnum } from 'types/UserRoleEnum';

export const selectedSizeToProperty = (selectedSize: ClothesSizeEnum) => {
    switch (selectedSize) {
        case ClothesSizeEnum.XS: return 'qtyXS';
        case ClothesSizeEnum.S: return 'qtyS';
        case ClothesSizeEnum.M: return 'qtyM';
        case ClothesSizeEnum.L: return 'qtyL';
        case ClothesSizeEnum.XL: return 'qtyXL';
        case ClothesSizeEnum.EIGHT: return 'qty8';
        case ClothesSizeEnum.EIGHT_HALF: return 'qty85';
        case ClothesSizeEnum.NINE: return 'qty9';
        case ClothesSizeEnum.NINE_HALF: return 'qty95';
        case ClothesSizeEnum.TEN: return 'qty10';
    }
}

export const selectedSizeToString = (selectedSize: ClothesSizeEnum) => {
    switch (selectedSize) {
        case ClothesSizeEnum.XS: return 'XS';
        case ClothesSizeEnum.S: return 'S';
        case ClothesSizeEnum.M: return 'M';
        case ClothesSizeEnum.L: return 'L';
        case ClothesSizeEnum.XL: return 'XL';
        case ClothesSizeEnum.EIGHT: return '8';
        case ClothesSizeEnum.EIGHT_HALF: return '8.5';
        case ClothesSizeEnum.NINE: return '9';
        case ClothesSizeEnum.NINE_HALF: return '9.5';
        case ClothesSizeEnum.TEN: return '10';
    }
}

export const productSexToString = (sex: ProductSexEnum) => {
    switch(sex) {
        case ProductSexEnum.FEMALE: return 'K';
        case ProductSexEnum.MALE: return 'M';
        case ProductSexEnum.UNISEX: return 'Unisex';
        default: return ''
    }
}

export const shoeSizeStrToNumber = (size: string) => {
    return Number(size.replaceAll(',', '.'));
}

export const getAvailableSizesByVariants = (variants: Array<ProductVariantInterface>) => {
    const activeVariants = _.filter(variants, v => !v.ignore);
    if (activeVariants.length === 0) return [];

    const firstVariant = activeVariants[0];
    if (Number.isNaN(shoeSizeStrToNumber(firstVariant.size))) {
        return getAvailableClothesSizes(activeVariants);
    }
    else {
        return getAvailableShoeSizes(activeVariants);
    }
}

export const getAvailableShoeSizes = (variants: Array<ProductVariantInterface>) => {
    const uniqueSizes = _.uniqBy(variants, v => v.size);

    const sizes = _.map(uniqueSizes, v => shoeSizeStrToNumber(v.size));
    const orderedSizes = sizes.sort((a, b) => a - b);

    return orderedSizes;
}

export const getAvailableClothesSizes = (variants: Array<ProductVariantInterface>) => {
    const uniqueSizes = _.uniqBy(variants, v => v.size);
    const sizes = _.map(uniqueSizes, v => v.size.toLowerCase());

    const orderedSizes: Array<string> = [];
    const addIfAvailable = (size: string) => {
        if (sizes.includes(size)) orderedSizes.push(size);
    }

    addIfAvailable('xxs');
    addIfAvailable('xs');
    addIfAvailable('s');
    addIfAvailable('m');
    addIfAvailable('l');
    addIfAvailable('xl');
    addIfAvailable('2xl');
    addIfAvailable('3xl');
    addIfAvailable('4xl');
    addIfAvailable('5xl');

    return orderedSizes;
}

export const canSelectProductQuantity = () => {
    if (authStore.group!.maxQuantityPrItemRule > 1) return true;

    if (authStore.user?.role === UserRoleEnum.ADMIN &&
        (placeOrderStore.reasonForOrder === ReasonForOrderEnum.COLLECTION || placeOrderStore.reasonForOrder === ReasonForOrderEnum.RESERVE)) {
        return true;
    }

    return false;
}

export const orderStatusEnumToString = (status: OrderStatusEnum) => {
    switch(status) {
        case OrderStatusEnum.Pending: return 'Afventer';
        case OrderStatusEnum.Approved: return 'Godkendt';
        case OrderStatusEnum.PendingDispatch: return 'Pakket';
        case OrderStatusEnum.Dispatched: return 'Afsendt';
        case OrderStatusEnum.Cancelled: return 'Annulleret';
    }
}