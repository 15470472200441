import { action, makeObservable, observable } from 'mobx';
import { NewsInterface } from 'types/NewsInterface';
import { BaseStore } from './BaseStore';

class NewsDetailsStore extends BaseStore {

    news: NewsInterface | null = null;

    constructor() {
        super();

        makeObservable(this, {
            news: observable,

            resetStore: action,
            getNews: action,
            updateNews: action,
            deleteNews: action
        })
    }

    resetStore = () => {
        this.news = null;
    }

    getNews = (id: string) => {
        return this.get(`news/${id}`, 'news');
    }

    updateNews = (news: NewsInterface) => {
        return this.put(`news/${news.id!}`, news, 'news');
    }

    deleteNews = (newsId: string) => {
        return this.delete(`news/${newsId}`, newsId, this.news);
    }

}

export const newsDetailsStore = new NewsDetailsStore();