import { Modal } from '@material-ui/core';
import Button from 'components/Forms/Button';
import Form from 'components/Forms/Form';
import Input from 'components/Forms/Input';
import Header from 'components/Header';
import ModalCreate from 'components/ModalCreate';
import { adminRoutes } from 'components/Routes/AdminRoutes';
import SelectUserRole from 'components/SelectUserRole';
import SidebarMenuContainerAdmin, { ActiveMenuEnum } from 'components/SidebarMenuContainerAdmin';
import Table from 'components/Table';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { userStore } from 'stores/UserStore';
import { UserInterface } from 'types/UserInterface';

const NAME_NAME = 'name';
const EMAIL_NAME = 'email';
const ROLE_NAME = 'role';

const PageUsers = observer(() => {
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        userStore.getUsers();

        return () => {
            userStore.resetStore();
        }
    }, [])

    const onCreate = async (data: any) => {
        const user = await userStore.createUser(data[NAME_NAME], data[EMAIL_NAME], data[ROLE_NAME]);
        if (user.id) {
            navigate(generatePath(adminRoutes.pageUserDetails, { id: user.id }));
        }
    }

    const users = _.filter(userStore.users, (u) => {
        if (!searchQuery) return true;

        return (
            u.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1
            || u.email.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1
        )
    });

    return (
        <div>
            <Header />
            <SidebarMenuContainerAdmin
                activeMenuItem={ActiveMenuEnum.USERS}>
                <div className='flex justify-between items-center mb-6'>
                    <input
                        onChange={(e) => setSearchQuery(e.currentTarget.value)}
                        type="text"
                        className='border mb-2 py-2 px-4 rounded w-64'
                        placeholder='Søg på navn eller email...'
                    />

                    <ModalCreate
                        onSubmit={onCreate}
                        validation={(yup) => ({
                            name: yup.string().required('Navn er påkrævet'),
                            email: yup.string().required('Email er påkrævet'),
                            role: yup.string().required('Rolle er påkrævet')
                        })}
                        modalTitle='Opret ny bruger'
                        buttonLabel='Ny bruger'
                        modalSubmitLabel='Opret bruger'
                        formElements={
                            <>
                                <Input
                                    label='Navn'
                                    name={NAME_NAME}
                                />

                                <Input
                                    label='Email'
                                    name={EMAIL_NAME}
                                    className='mt-6'
                                />

                                <SelectUserRole
                                    name={ROLE_NAME}
                                    label='Privilegier'
                                    className='mt-6'
                                />
                            </>
                        }
                    />
                </div>

                <Table
                    data={users}
                    render={[
                        {
                            label: 'Navn',
                            sortProperty: (item) => item.name,
                            cell: (item) => item.name
                        },
                        {
                            label: 'Email',
                            sortProperty: (item) => item.email,
                            cell: (item) => item.email
                        }
                    ]}
                    onRowClicked={(item: UserInterface) => navigate(generatePath(adminRoutes.pageUserDetails, { id: item.id }))}
                />

            </SidebarMenuContainerAdmin>
        </div>
    )
})

export default PageUsers;