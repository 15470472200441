import Input from 'components/Forms/Input';
import Textarea from 'components/Forms/Textarea';
import Header from 'components/Header';
import ModalCreate from 'components/ModalCreate';
import { adminRoutes } from 'components/Routes/AdminRoutes';
import SidebarMenuContainerAdmin, { ActiveMenuEnum } from 'components/SidebarMenuContainerAdmin';
import Table from 'components/Table';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useEffect } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { newsStore } from 'stores/NewsStore';
import { NewsInterface } from 'types/NewsInterface';

const TITLE_NAME = 'title';
const TEXT_NAME = 'text';

const PageNews = observer(() => {
    const navigate = useNavigate();

    useEffect(() => {
        newsStore.getNews();

        return () => {
            newsStore.resetStore();
        }
    }, [])

    const onCreate = async (data: any) => {
        await newsStore.createNews(
            data[TITLE_NAME],
            data[TEXT_NAME]
        )
    }

    const news = newsStore.news;

    return (
        <div>
            <Header />
            <SidebarMenuContainerAdmin
                activeMenuItem={ActiveMenuEnum.NEWS}>

                <div className='text-right mb-6'>
                    <ModalCreate
                        onSubmit={onCreate}
                        validation={(yup) => ({
                            [TITLE_NAME]: yup.string().required('Titel er påkrævet'),
                            [TEXT_NAME]: yup.string().required('Beskrivelse er påkrævet'),
                        })}
                        modalTitle='Opret nyhed'
                        buttonLabel='Opret nyhed'
                        modalSubmitLabel='Opret nyhed'
                        formElements={
                            <>
                                <Input
                                    label='Titel'
                                    name={TITLE_NAME}
                                />

                                <Textarea
                                    label='Beskrivelse'
                                    name={TEXT_NAME}
                                    className='mt-6'
                                />

                            </>
                        }
                    />
                </div>

                <Table
                    data={_.clone(news)}
                    render={[
                        {
                            label: 'Dato',
                            sortProperty: (item: NewsInterface) => moment(item.createdOn).format('DD/MM/YYYY HH:mm'),
                            cell: (item) => moment(item.createdOn).format('DD/MM/YYYY HH:mm')
                        },
                        {
                            label: 'Titel',
                            cell: (item) => item.title
                        },
                        {
                            label: 'Beskrivelse',
                            cell: (item) => {
                                const truncate = item.text?.length > 25 ? `${item.text.substring(0, 25)}...` : item.text;
                                return truncate;
                            }
                        }
                    ]}
                    onRowClicked={(item: NewsInterface) => navigate(generatePath(adminRoutes.pageNewsDetails, { id: item.id }))}
                    rowStyle={(item, index) => ({
                        background: index % 2 === 0 ? '#fafafa' : undefined,
                        cursor: 'pointer'
                    })}
                />

            </SidebarMenuContainerAdmin>
        </div>
    )
})

export default PageNews;