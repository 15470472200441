import Header from 'components/Header';
import 'react-datepicker/dist/react-datepicker.css';
import OrdersTable from 'components/OrdersTable';
import SidebarMenuContainerFrontpage, { ActiveMenuEnum } from 'components/SidebarMenuContainerFrontPage';

const PageUserOrders = () => {

    return (
        <div>
            <Header />
            <SidebarMenuContainerFrontpage
                activeMenuItem={ActiveMenuEnum.ORDER_HISTORY}>
                
                <OrdersTable />
            </SidebarMenuContainerFrontpage>
        </div>
    )
}

export default PageUserOrders;