import { observer } from 'mobx-react-lite';
import logoSrc from './logo.png';
import { authStore } from 'stores/AuthStore';
import { urlpathToString } from 'helpers/UrlpathHelper';
import { useNavigate } from 'react-router-dom';
import { routes } from 'components/Routes';

const Header = observer(() => {
    const navigate = useNavigate();

    return (
        <div
            style={{ borderBottom: '10px solid #3F5B58' }}
            className='
                py-5
                flex
                items-center
            '>
            <img src={logoSrc} alt='Forsvarsministeret - Materiel og Indkøbsstyrelsen' width={350} />
            <p className='text-md text-primary-green font-semibold'>
                {authStore.urlpath && urlpathToString(authStore.urlpath)}
            </p>
            <div className='flex-1 text-right self-center'>
                <button 
                    className='underline'
                    type='button' 
                    onClick={() => navigate(routes.pageUserProfile)}>
                    {authStore.user?.email}
                </button>
                <button
                    type='button'
                    onClick={authStore.logout}
                    className='
                        mx-6
                        px-4
                        py-1
                        text-sm
                        rounded
                        bg-[#DADADA]
                    '>
                    Log ud
                </button>
            </div>
        </div>
    )
})

export default Header;