interface Props {
    label: string,
    onClick: () => void,
    isActive?: boolean
}

const SidebarMenuItem = (props: Props) => {
    return (
        <li
            onClick={props.onClick}
            className={`
                cursor-pointer 
                block
                mb-1 ${props.isActive ? 'font-semibold' : ''}
            `}>
            {props.label}
        </li>
    )
}

export default SidebarMenuItem;