import { action, makeObservable, observable } from 'mobx';
import { ProductInterface } from 'types/ProductInterface';
import { ProductVariantInterface } from 'types/ProductVariantInterface';
import { BaseStore } from './BaseStore';

class ProductDetailsStore extends BaseStore {

    product: ProductInterface | null = null;
    variants: Array<ProductVariantInterface> = [];

    constructor() {
        super();

        makeObservable(this, {
            product: observable,
            variants: observable,

            resetStore: action,
            getProduct: action,
            updateProduct: action,
            deleteProduct: action,
            getVariants: action,
            updateVariant: action
        })
    }

    resetStore = () => {
        this.product = null;
        this.variants = [];
    }

    getProduct = (id: string) => {
        return this.get(`products/${id}`, 'product');
    }

    updateProduct = (product: ProductInterface) => {
        return this.put(`products/${product.id}`, product, this.product);
    }

    deleteProduct = (id: string) => {
        return this.delete(`products/${id}`, id, this.product);
    }

    getVariants = (productId: string) => {
        return this.get(`products/${productId}/variants`, 'variants');
    }

    updateVariant = (productId: string, variant: ProductVariantInterface) => {
        return this.put(`products/${productId}/variants/${variant.id}`, variant, this.variants);
    }

}

export const productDetailsStore = new ProductDetailsStore();