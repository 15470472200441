import FormElementInterface from "types/FormElementInterface";
import Error from "components/Forms/Error";
import Label from "components/Forms/Label";
import { useFormContext } from "react-hook-form";
import css from './style.module.scss';
import { useEffect } from "react";

export interface OptionInterface {
    id: string,
    value: string
}

interface Props extends FormElementInterface {
    options: Array<OptionInterface>,
    disabled?: boolean,
    className?: string
}

const Select = (props: Props) => {
    const { register, unregister } = useFormContext();

    useEffect(() => {
        return () => {
            unregister(props.name)
        }
    }, []);

    return (
        <div className={`w-full ${props.disabled ? 'cursor-not-allowed' : ''} ${props.className || ''}`}>
            <Label name={props.name} label={props.label} />
            <select
                className={`border p-2 h-10 w-full ${props.disabled ? css.disabled : ''}`}
                {...register(props.name)}
                defaultValue={props.defaultValue || ''}>
                {
                    props.placeholder &&
                    <option style={{ background: 'blue' }} value=''>{props.placeholder}</option>
                }

                {
                    props.options.map((opt, index) => (
                        <option key={index} value={opt.id}>
                            {opt.value}
                        </option>
                    ))
                }
            </select>
            <Error name={props.name} />
        </div>
    )
}

export default Select;