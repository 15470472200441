import Button from 'components/Forms/Button';
import Form from 'components/Forms/Form';
import Input from 'components/Forms/Input';
import Header from 'components/Header';
import { routes } from 'components/Routes';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { authStore } from 'stores/AuthStore';

const CURRENT_PASSWORD_NAME = 'currentPassword';
const NEW_PASSWORD1_NAME = 'newPassword1';
const NEW_PASSWORD2_NAME = 'newPassword2';

const PageUserProfile = observer(() => {
    const navigate = useNavigate();

    const onSubmit = async (data: any) => {
        if (data[NEW_PASSWORD1_NAME] !== data[NEW_PASSWORD2_NAME]) {
            toast('Dit nye password skal skrives ens i begge felter', { type: 'error' });
            return;
        }

        try {
            await authStore.changePassword(data[CURRENT_PASSWORD_NAME], data[NEW_PASSWORD1_NAME]);
            toast('Password opdateret!', { type: 'success' });
        }
        catch (error) {
            toast('Der opstod en fejl. Har du husket at skrive dit rigtige nuværende password?', { type: 'error' });
        }
    }

    return (
        <div>
            <Header />
            <div className='py-16 w-72 mx-auto'>
                <h1 className='text-2xl mb-12 text-center'>Skift password</h1>
                <Form 
                    onSubmit={onSubmit}
                    resetOnSubmit
                    validation={(yup) => ({
                        [CURRENT_PASSWORD_NAME]: yup.string().min(6, 'Skal være minimum 6 karakterer langt').required('Påkrævet'),
                        [NEW_PASSWORD1_NAME]: yup.string().min(6, 'Skal være minimum 6 karakterer langt').required('Påkrævet'),
                        [NEW_PASSWORD2_NAME]: yup.string().min(6, 'Skal være minimum 6 karakterer langt').required('Påkrævet'),
                    })}
                    >

                    <Input
                        label='Nuværende password'
                        name={CURRENT_PASSWORD_NAME}
                        type='password'
                    />

                    <Input
                        label='Nyt password'
                        name={NEW_PASSWORD1_NAME}
                        type='password'
                        className='mt-6'
                    />

                    <Input
                        label='Nyt password igen'
                        name={NEW_PASSWORD2_NAME}
                        type='password'
                        className='mt-6'
                    />

                    <div className='flex justify-around mt-12'>
                        <Button
                            type='button'
                            appearance='secondary'
                            label='Tilbage'
                            onClick={() => navigate(routes.pageFront)}
                        />
                        <Button
                            type='submit'
                            appearance='primary'
                            label='Opdatér password'
                        />
                    </div>

                </Form>

            </div>
        </div>
    )
})

export default PageUserProfile;