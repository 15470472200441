import Button from "components/Forms/Button";
import Form from "components/Forms/Form";
import Label from "components/Forms/Label";
import Textarea from "components/Forms/Textarea";
import { routes } from "components/Routes";
import SelectAddress from "components/SelectAddress";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { cartStore } from "stores/CartStore";
import { placeOrderStore } from "stores/PlaceOrderStore";
import { OrderInterface } from 'types/OrderInterface';
import { OrderStatusEnum } from 'types/OrderStatusEnum';
import { ReasonForOrderEnum } from 'types/ReasonForOrderEnum';
import { YupInterface } from "types/YupInterface";
import CartItem from "./CartItem";

const MESSAGE_NAME = 'message';
const ADDRESS_ID_NAME = 'address';

const Cart = observer(() => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const navigate = useNavigate();

    const onSubmitOrder = async (data: { [MESSAGE_NAME]: string, [ADDRESS_ID_NAME]: string }) => {
        try {
            setIsSubmitting(true);
            const placedOrder = await cartStore.placeOrder(
                placeOrderStore.employeeNo,
                placeOrderStore.reasonForOrder,
                data[MESSAGE_NAME],
                data[ADDRESS_ID_NAME],
                cartStore.items.map(
                    (item) => (
                        {
                            quantity: item.quantity,
                            variantId: item.variantId
                        }
                    )
                )
            ) as OrderInterface;

            // Nothing ordered is in stock. Entire order is backorder
            if (placedOrder.status === OrderStatusEnum.BackOrder) {
                toast('Din bestilling er modtaget! De bestilte varer er ikke på lager pt. men fremsendes så snart de er tilgængelige. Se menupunktet "Restordrer" for yderligere detaljer.', { type: 'warning', autoClose: 6000 });
            }
            // Some of the items are not available. Part of the order is backorder
            else if (placedOrder.backOrderId) {
                toast('Din bestilling er modtaget! En eller flere varer er i restordre og fremsendes så snart de er tilbage på lager. Se menupunktet "Restordrer" for yderligere detaljer.', { type: 'success', autoClose: 6000 });
            }
            else {
                toast('Din bestilling er modtaget! Du modtager en mail med bekræftelse når ordren er godkendt.', { type: 'success', autoClose: 4000 });
            }

            placeOrderStore.resetStore();
            cartStore.resetStore();

            setIsSubmitting(false);

            navigate(routes.pageFront);
        }
        catch (error) {
            setIsSubmitting(false)
        }
    }

    const validation = (yup: YupInterface) => {
        return {
            address: yup.string().required('Leveringssted skal udfyldes')
        }
    }

    const defaultMessage = () => {
        if (placeOrderStore.reasonForOrder === ReasonForOrderEnum.COLLECTION) return ReasonForOrderEnum.COLLECTION.toString();
        else if (placeOrderStore.reasonForOrder === ReasonForOrderEnum.RESERVE) return ReasonForOrderEnum.RESERVE.toString();
        else return ''
    }

    const items = cartStore.items;

    return (
        <div
            className='
                relative
                w-[350px]
                p-5
                shadow-md
                mr-5
                bg-[#f6f6f6]
            '>
            <div className='sticky top-10'>
                <p className='mb-7 text-primary-green'>
                    Indkøbskurv
                </p>

                <div>
                    {
                        items.length > 0 &&
                        <div className='mb-4'>
                            <Label name='items' label='Produkter' />
                        </div>
                    }

                    {
                        items.map((item, key) => {
                            return <CartItem item={item} key={key} />
                        })
                    }

                    {
                        items.length > 0 &&
                        <p className='text-xs'>
                            OBS: Husk at du kan tilføje lige så mange produkter til kurven du ønsker, før du afgiver din bestilling.
                        </p>
                    }

                    {
                        items.length === 0 &&
                        <p className='text-sm'>
                            For at påbegynde din bestilling, bedes du tilføje et eller flere produkter til kurven.
                        </p>
                    }

                </div>
                {
                    items.length > 0 &&
                    <div className='mt-7'>
                        <Form onSubmit={onSubmitOrder} validation={validation} formOptions={{
                            defaultValues: {
                                'message': defaultMessage()
                            }
                        }}>
                            <SelectAddress
                                name='address'
                            />

                            <div className='mt-7'>
                                <Textarea
                                    label='Kort besked ifm. levering'
                                    name='message'
                                />
                            </div>
                            <div className='text-right mt-6'>
                                <Button
                                    className='mr-4'
                                    appearance='secondary'
                                    label='Tøm kurv'
                                    type='button'
                                    onClick={cartStore.resetStore}
                                />
                                <Button
                                    disabled={isSubmitting}
                                    appearance={isSubmitting ? 'secondary' : 'primary'}
                                    label={isSubmitting ? 'Arbejder...' : 'Afgiv bestilling'}
                                    type='submit'
                                />
                            </div>

                        </Form>
                    </div>
                }
            </div>
        </div>
    )
})

export default Cart;