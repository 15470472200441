import { action, makeObservable, observable } from 'mobx';
import { NewsInterface } from 'types/NewsInterface';
import { string } from 'yup';
import { BaseStore } from './BaseStore';

class NewsStore extends BaseStore {

    news: Array<NewsInterface> = [];

    constructor() {
        super();

        makeObservable(this, {
            news: observable,

            resetStore: action,
            getNews: action,
            createNews: action
        })
    }

    resetStore = () => {
        this.news = [];
    }

    getNews = () => {
        return this.get(`news`, 'news');
    }

    createNews = (title: string, text: string) => {
        return this.post(`news`, {
            title: title,
            text: text
        }, this.news);
    }

}

export const newsStore = new NewsStore();