import { Modal } from '@material-ui/core';
import Button from 'components/Forms/Button';
import Form from 'components/Forms/Form';
import { useState } from 'react';
import { YupInterface } from 'types/YupInterface';
import { ObjectShape } from 'yup/lib/object';

interface Props {
    onSubmit: (data: any) => void,
    validation?: (yupInstance: YupInterface) => ObjectShape,
    modalTitle: string,
    modalSubmitLabel: string,
    buttonLabel: string,
    formElements: React.ReactNode

}

const ModalCreate = (props: Props) => {
    const [showCreateModal, setShowCreateModal] = useState(false);

    const onSubmit = (data: any) => {
        props.onSubmit(data);
        setShowCreateModal(false);
    }

    return (
        <div>
            <Button
                appearance='primary'
                type='button'
                label={props.buttonLabel}
                onClick={() => setShowCreateModal(true)}
            />

            <Modal
                open={showCreateModal}>
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 650,
                    background: 'white',
                    borderRadius: '12px',
                    padding: '40px 0px',
                }}>
                    <h1 className='text-2xl text-center'>{props.modalTitle}</h1>
                    <div className='my-8 w-64 mx-auto'>
                        <Form
                            onSubmit={onSubmit}
                            validation={props.validation}>

                            {props.formElements}

                            <div className='flex justify-around mt-8'>
                                <Button
                                    type='button'
                                    appearance='secondary'
                                    label='Fortryd'
                                    onClick={() => setShowCreateModal(false)}
                                />
                                <Button
                                    type='submit'
                                    appearance='primary'
                                    label={props.modalSubmitLabel}
                                />
                            </div>

                        </Form>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default ModalCreate;