import { action, computed, makeObservable, observable } from "mobx";
import { EmployeeNumberRuleEnum } from 'types/EmployeeNumberRuleEnum';
import { ReasonForOrderEnum } from "types/ReasonForOrderEnum";
import { authStore } from './AuthStore';
import SaveableStore from "./SaveableStore";

class PlaceOrderStore extends SaveableStore {
    employeeNo: string | null = null;
    reasonForOrder: ReasonForOrderEnum | null = null;

    constructor() {
        super('PlaceOrderStore');

        makeObservable(this, {
            employeeNo: observable,
            reasonForOrder: observable,

            resetStore: action,
            setEmployeeNo: action,
            setReasonForOrder: action,
            canOrder: computed
        });

        this.initSessionStorage(this, [
            'employeeNo',
            'reasonForOrder'
        ])
    }

    resetStore = () => {
        this.employeeNo = null;
        this.reasonForOrder = null;
    }

    setEmployeeNo = (employeeNo: string | null) => {
        this.employeeNo = employeeNo;
    }

    setReasonForOrder = (reason: ReasonForOrderEnum) => {
        this.reasonForOrder = reason;
    }

    get canOrder() {
        if (authStore.group?.employeeNumberRule === EmployeeNumberRuleEnum.NO_RULES) {
            return this.reasonForOrder !== null;
        }
        else {
            return this.employeeNo && this.reasonForOrder;
        }
    }

}

export const placeOrderStore = new PlaceOrderStore();