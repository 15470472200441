import { observer } from "mobx-react-lite";
import { productStore } from "stores/ProductStore";
import { CartItemInterface } from "types/CartItemInterface";
import { cartStore } from 'stores/CartStore';
import _ from 'lodash';
import { canSelectProductQuantity, productSexToString } from 'helpers/ProductHelper';
import { authStore } from 'stores/AuthStore';
import { toast } from 'react-toastify';

interface Props {
    item: CartItemInterface
}

const CartItem = observer((props: Props) => {

    const updateQty = () => {
        const newQty = window.prompt('Hvor mange stk. ønsker du at bestille?', props.item.quantity.toString());

        if (authStore.group?.allowBackOrders === false && props.item.maxQty && props.item.maxQty < Number(newQty)) {
            if (props.item.maxQty === 0) {
                toast(`Der er desværre ingen på lager i str. ${props.item.selectedSize}`, { type: 'error' });
            }
            else {
                toast(`Der er desværre kun ${props.item.maxQty} stk på lager i str. ${props.item.selectedSize}`, { type: 'error' });
            }
            return;
        }

        if (newQty && Number(newQty) > 0) {
            cartStore.addOrUpdateItem(props.item.productId, props.item.variantId, props.item.selectedSize, Number(newQty));
        }
    }

    const product = _.find(productStore.products, p => p.id === props.item.productId);
    if (!product) return null;

    return (
        <div className='flex items-center py-4 px-2 rounded mb-4 bg-white border relative'>
            <div className='px-2 text-sm flex-1'>
                {
                    canSelectProductQuantity() &&
                    <button
                        onClick={updateQty}
                        type='button'
                        className='underline text-primary-green'>
                        {props.item.quantity}x
                    </button>
                }

                {
                    !canSelectProductQuantity() &&
                    <span>{props.item.quantity}x</span>
                }

                <span>
                    &nbsp;{product.name} ({productSexToString(product.sex)}, str. {props.item.selectedSize})
                    {
                        props.item.quantity > props.item.maxQty! &&
                        <div className='text-red-700 text-xs font-semibold'>
                            Restordre ({props.item.maxQty} på lager)
                        </div>
                    }

                </span>
            </div>

            <button
                onClick={() => cartStore.removeItem(props.item.productId, props.item.variantId)}
                type='button'
                className='
                    cursor-pointer
                    absolute
                    top-[-10px]
                    right-[-10px]
                    bg-[#838383]
                    px-2
                    py-1
                    font-bold
                    text-white
                    text-xs
                    rounded-full
                '>
                X
            </button>
        </div>
    )
})

export default CartItem;