import { buildQuery } from 'helpers/QueryHelper';
import { action, makeObservable, observable, ObservableMap } from "mobx";
import { OrderInterface } from "types/OrderInterface";
import { PagingResultInterface } from "types/PagingResultInterface";
import { BaseStore } from "./BaseStore";

class OrderStore extends BaseStore {

    orders: PagingResultInterface<OrderInterface> | null = null;

    constructor() {
        super();

        makeObservable(this, {
            orders: observable,

            resetStore: action,
            getOrders: action,
            deleteOrder: action
        });
    }

    resetStore = () => {
        this.orders = null;
    }

    getOrders = (opts?: {
        employeeNo?: string,
        from?: string,
        to?: string,
        productName?: string,
        address?: string,
        creator?: string,
        jdOrderId?: string
    }, backOrders: boolean = false) => {
        let params = {} as any;
        
        if (opts?.employeeNo) {
            params['byEmployeeNumber'] = opts.employeeNo;
        }

        if (opts?.from) {
            params['from'] = opts.from;
        }

        if (opts?.to) {
            params['to'] = opts.to;
        }

        if (opts?.productName) {
            params['byProductName'] = opts.productName;
        }

        if (opts?.address) {
            params['byAddress'] = opts.address;
        }

        if (opts?.creator) {
            params['byCreator'] = opts.creator;
        }

        if (opts?.jdOrderId) {
            params['byJdOrderId'] = opts.jdOrderId;
        }

        let endpoint = 'orders';
        if (backOrders) {
            endpoint = 'orders/backorders';
        }

        return this.getPaged(buildQuery(endpoint, params), this.orders, 'orders', 50);
    }

    deleteOrder = (orderId: string) => {
        return this.delete(`orders/${orderId}`, orderId, this.orders?.items);
    }
}

export const orderStore = new OrderStore();