import { action, makeAutoObservable, makeObservable, observable } from 'mobx';

class LoggedInContainerStore {

    fullWidth: boolean = false;

    constructor() {
        makeObservable(this, {
            fullWidth: observable,

            resetStore: action,
            setFullWidth: action
        })
    }

    resetStore = () => {
        this.fullWidth = false;
    }

    setFullWidth = (value: boolean) => {
        this.fullWidth = value;
    }

}

export const loggedInContainerStore = new LoggedInContainerStore();