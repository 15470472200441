import { Controller, FieldValues, useFormContext, UseFormStateReturn } from "react-hook-form";
import FormElementInterface from "types/FormElementInterface";
import Select from 'react-select';
import Error from "components/Forms/Error";
import { OptionInterface } from "../Select";
import _ from "lodash";
import Label from "../Label";

interface Props extends FormElementInterface {
    isClearable?: boolean,
    options: Array<OptionInterface>,
    isDisabled?: boolean,
    onChange?: (name: string, value: string | null) => void
}

const SelectInput = (props: Props) => {
    const { control, setValue, trigger } = useFormContext();

    const onChange = (name: string, singleValue: OptionInterface | null) => {
        setValue(name, singleValue?.id);

        trigger(props.name);
        if (props.onChange) {
            props.onChange(name, singleValue?.id || null);
        }
    }

    return (
        <div>
            <Controller
                name={props.name}
                control={control}
                render={({
                    field: { onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                }) => (
                    <div className='w-full async-select relative'>
                        <Label name={props.name} label={props.label} />
                        <Select
                            isDisabled={props.isDisabled}
                            onChange={(v) => onChange(name, v)}
                            options={props.options}
                            isClearable={props.isClearable}
                            value={_.find(props.options, (o) => o.id === value)}
                            placeholder={props.placeholder}
                            getOptionValue={o => o.id}
                            getOptionLabel={o => o.value}
                            menuPortalTarget={document.body}
                            components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                            styles={{
                                menuList: (provided, state) => ({
                                    ...provided,
                                    fontSize: '12px'
                                }),
                                menuPortal: (provided, state) => ({
                                    ...provided,
                                    zIndex: 9999
                                }),
                                control: (provided, state) => ({
                                    ...provided,
                                    height: '40px',
                                    borderRadius: 2,
                                    borderColor: '#e5e7eb',
                                    // These lines disable the blue border
                                    border: '1px solid #e5e7eb !important',
                                    boxShadow: 'none',
                                })
                            }}
                        />
                        <Error name={props.name} />
                    </div>
                )} />
        </div>
    )
}

export default SelectInput;