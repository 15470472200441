import Select, { OptionInterface } from "components/Forms/Select";
import { observer } from 'mobx-react-lite';
import { authStore } from 'stores/AuthStore';
import { ReasonForOrderEnum } from "types/ReasonForOrderEnum";
import { UserRoleEnum } from 'types/UserRoleEnum';

interface Props {
    className?: string,
    name: string
}

const SelectReasonForOrdering = observer((props: Props) => {

    const options: Array<OptionInterface> = [
        {
            id: ReasonForOrderEnum.FIRST_TIME_ORDER,
            value: 'Førstegangsbestilling'
        },
        {
            id: ReasonForOrderEnum.WRONG_SIZE,
            value: 'Bestilt forkert størrelse'
        },
        {
            id: ReasonForOrderEnum.REPLACEMENT,
            value: 'Erstatning'
        },
        {
            id: ReasonForOrderEnum.TEAR,
            value: 'Nedslidning'
        },
        {
            id: ReasonForOrderEnum.REPLACEMENT,
            value: 'Ombytning (Forkert leveret)'
        },
        {
            id: ReasonForOrderEnum.COMPLAINT,
            value: 'Reklamation'
        }
    ]

    if (authStore.user?.role === UserRoleEnum.ADMIN) {
        options.push(...[
            {
                id: ReasonForOrderEnum.RESERVE,
                value: 'Reservebeholdning'
            },
            {
                id: ReasonForOrderEnum.COLLECTION,
                value: 'Kollektionsbestilling'
            }
        ])
    }

    return (
        <Select
            label='Årsag til bestilling'
            name={props.name}
            className={props.className}
            placeholder='Vælg årsag'
            options={options}
        />
    )
})

export default SelectReasonForOrdering;