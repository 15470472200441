import Header from 'components/Header';
import 'react-datepicker/dist/react-datepicker.css';
import OrdersTable from 'components/OrdersTable';
import SidebarMenuContainerFrontpage, { ActiveMenuEnum } from 'components/SidebarMenuContainerFrontPage';

const PageUserBackOrders = () => {

    return (
        <div>
            <Header />
            <SidebarMenuContainerFrontpage
                activeMenuItem={ActiveMenuEnum.BACK_ORDERS}>
                
                <OrdersTable backOrders={true} />
            </SidebarMenuContainerFrontpage>
        </div>
    )
}

export default PageUserBackOrders;