interface Props {
    label: string,
    onClick?: () => void,
    className?: string,
    type: 'button' | 'submit' | 'reset',
    appearance: 'primary' | 'secondary',
    disabled?: boolean
}

const Button = (props: Props) => {

    const primaryClass = 'text-white rounded bg-primary-green py-2 px-7 text-sm ';
    const secondaryClass = 'text-black rounded bg-[#DADADA] py-2 px-4 text-sm ';

    return (
        <button
            disabled={props.disabled}
            type={props.type}
            onClick={props.onClick}
            className={`
                ${props.appearance === 'primary' ? primaryClass : ''}
                ${props.appearance === 'secondary' ? secondaryClass : ''}
                ${props.className || ''}
            `}>
            {props.label}
        </button>
    )

}

export default Button;