interface Props {
    name: string,
    label?: string
}

const Label = (props: Props) => {
    if (!props.label) return null;

    return (
        <label
            className='text-sm font-medium mb-1'
            htmlFor={props.name}>
            {props.label}
        </label>
    )
}

export default Label;