import SelectSize from "components/SelectSize";
import { observer } from "mobx-react-lite";
import { cartStore } from "stores/CartStore";
import { ProductInterface } from "types/ProductInterface";
import _ from 'lodash';
import Form from 'components/Forms/Form';
import SelectQuantity from 'components/SelectQuantity';
import { authStore } from 'stores/AuthStore';
import moment from 'moment';
import { toast } from 'react-toastify';
import { canSelectProductQuantity, productSexToString } from 'helpers/ProductHelper';
import NoImgPlaceholderSvg from './noimage-placeholder.svg';

interface Props {
    product: ProductInterface
}

const ProductItem = observer((props: Props) => {

    const onAddToBasket = (data: any) => {
        const qty = Number(data.quantity) || 1;
        
        const variant = _.find(props.product.variants, v => v.size.toLowerCase().replace(',', '.') === data.size.toString());
        if (!variant) throw Error('Variant kunne ikke findes');

        if (authStore.group?.allowBackOrders === false && variant.quantity < qty) {
            if (variant.quantity === 0) {
                toast(`Der er desværre ingen på lager i str. ${variant.size}`, { type: 'error' });
            }
            else {
                toast(`Der er desværre kun ${variant.quantity} stk på lager i str. ${variant.size}`, { type: 'error' });
            }

            return;
        }

        cartStore.addOrUpdateItem(props.product.id, variant.id, data.size, qty, variant.quantity);
        toast('Tilføjet til kurv', { type: 'success' });
    }

    const { product } = props;
    const alreadyInCart = _.some(cartStore.items, i => i.productId === product.id);
    const shouldDisableProduct = !canSelectProductQuantity() && alreadyInCart;
    const variantSkuIdentifier = product.variants[0]?.sku?.split('-')[0];

    return (
        <div
            className={`
                border
                mb-6
                flex
                flex-start
                ${shouldDisableProduct ? 'opacity-50' : ''}
            `}>
            <div
                style={{ borderRight: '1px solid #d6d6d6' }}
                className='flex w-[270px] bg-gray-50 px-2'>
                {
                    product.images.length > 0 &&
                    <img
                        src={product.images[0].url}
                        alt={product.images[0].name}
                        style={{ objectFit: 'contain' }}
                    />
                }

                {
                    product.images.length === 0 &&
                    <img
                        src={NoImgPlaceholderSvg}
                        alt="Intet produktbillede tilgængeligt"
                        style={{ objectFit: 'contain' }}
                    />
                }
            </div>

            <div className='p-4 w-full'>
                <div className='flex flex-col'>
                    <div className='flex-1'>
                        <h1 className='text-lg mb-3'>
                            {product.name}
                        </h1>
                        <div>
                            <ul className='text-sm mb-4'>
                                {
                                    variantSkuIdentifier &&
                                    <li><strong>Varenr:</strong> {variantSkuIdentifier}</li>
                                }
                                <li><strong>Køn:</strong> {productSexToString(product.sex)}</li>
                                {
                                    product.color &&
                                    <li><strong>Farve:</strong> {product.color}</li>
                                }
                            </ul>
                        </div>

                        <p className='mb-4'>
                            {product.description}
                        </p>

                        {
                            _.some(product.variants, v => !v.ignore && v.quantity === 0 && v.expectedInStockDate !== null) &&
                            <div className='mt-4 mb-8 text-xs text-red-800'>
                                <p className='mb-1'><strong>Restordre:</strong></p>
                                {
                                    _.filter(product.variants, v => !v.ignore && v.quantity === 0 && v.expectedInStockDate !== null).map((variant, index) => {
                                        return (
                                            <p key={index}>
                                                Str. {variant.size}
                                                {variant.expectedInStockDate
                                                    ? ` (Forventet på lager ${moment(variant.expectedInStockDate).format('DD/MM/YYYY')})`
                                                    : ''}
                                            </p>
                                        )
                                    })
                                }
                            </div>
                        }

                    </div>

                    <div className='text-right'>
                        <Form
                            onSubmit={onAddToBasket}
                            validation={(yup) => ({
                                quantity: authStore.group!.maxQuantityPrItemRule > 1 ? yup.string().required() : yup.string(),
                                size: yup.string().required()
                            })}>

                            {
                                canSelectProductQuantity() &&
                                <SelectQuantity
                                    className='mr-2'
                                    name='quantity'
                                    maxQuantity={authStore.group?.maxQuantityPrItemRule && authStore.group?.maxQuantityPrItemRule > 1 ? authStore.group?.maxQuantityPrItemRule : 50}
                                />

                            }

                            {
                                !shouldDisableProduct &&
                                <SelectSize
                                    name='size'
                                    variants={product.variants}
                                    placeholder='Vælg str.'
                                />
                            }
                            <button
                                type='submit'
                                disabled={shouldDisableProduct}
                                className={`
                                text-right
                                ml-2
                                ${shouldDisableProduct ? 'bg-[#f6f6f6]' : 'bg-primary-green'}
                                ${shouldDisableProduct ? 'text-black' : 'text-white'}
                                py-2 px-6
                                rounded
                        `}>
                                {shouldDisableProduct ? 'Lagt i kurv' : 'Tilføj til kurv'}
                            </button>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default ProductItem;