import Select from 'components/Forms/Select'
import { userRoleEnumToString } from 'helpers/UserHelper'
import { UserRoleEnum } from 'types/UserRoleEnum'

interface Props {
    label: string,
    name: string,
    className?: string
}

const SelectUserRole = (props: Props) => {

    return (
        <Select
            className={props.className}
            name={props.name}
            label={props.label}
            options={[
                {
                    id: UserRoleEnum.USER.toString(),
                    value: userRoleEnumToString(UserRoleEnum.USER)
                },
                {
                    id: UserRoleEnum.ADMIN.toString(),
                    value: userRoleEnumToString(UserRoleEnum.ADMIN)
                }
            ]}
        />
    )

}

export default SelectUserRole;