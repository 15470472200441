import SelectAsync from 'components/Forms/SelectAsync';
import { observer } from 'mobx-react-lite';
import { OptionsOrGroups } from 'react-select';
import { authStore } from 'stores/AuthStore';
import { selectEmployeeStore } from 'stores/SelectEmployeeStore';
import { UserRoleEnum } from 'types/UserRoleEnum';

interface Props {
    name: string
}

const SelectEmployee = observer((props: Props) => {

    const isAdmin = authStore.user?.role === UserRoleEnum.ADMIN;

    const loadOptions = async (value: string, callback: (options: OptionsOrGroups<any, any>) => void) => {
        if (!value) {
            selectEmployeeStore.resetStore();
            callback([]);
        }

        if (isAdmin && value === '00000000') {
            callback([{
                id: '00000000',
                name: '00000000'
            }])
        }

        if (value.length > 2) {
            const results = await selectEmployeeStore.getEmployees(value);
            if (results) {
                callback(results.map((entity) => ({
                    id: entity.number,
                    name: entity.number
                })))
            }
        }
    }

    return <SelectAsync
        label='Medarbejdernummer'
        placeholder='Søg på medarbejdernummer'
        name={props.name}
        loadOptions={loadOptions}
    />

})

export default SelectEmployee;